import { Stack } from '@mui/material';
import ExerciseSetExpanded from './ExerciseSetExpanded';
import { DeleteOutline } from '@mui/icons-material';

export default function ExerciseItem({
  exercise,
  indexI,
  indexJ,
  moveUp,
  moveDown,
  handleSetsUpdate,
  isExpanded,
  removeExercise,
  isSuperset,
  isEndIndex,
  onChangeNotes,
}) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

  const renderArrows = () => {
    const result = [];
    if (!(indexI === 0 && indexJ === 0)) {
      result.push(
        <div
          key="arrow-up"
          onClick={() => moveUp(indexI, isSuperset ? indexJ : -1)}
          className="exer-up-arrow"
        >
          <img src="/assets/color-arrow.png" alt="Arrow Up" />
        </div>,
      );
    }

    if (!isEndIndex) {
      result.push(
        <div
          key="arrow-down"
          onClick={() => moveDown(indexI, isSuperset ? indexJ : -1)}
          className="exer-down-arrow"
        >
          <img src="/assets/color-arrow.png" alt="Arrow Down" />
        </div>,
      );
    }
    return result;
  };

  const renderExerciseCard = () => {
    return (
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <h4 style={{ marginBottom: 20, marginTop: 5 }}>
            {`${alphabet[indexI]}${isSuperset ? `.${indexJ + 1}` : ''}) `}{' '}
            {exercise?.ExerciseName}
          </h4>
          <div
            className="exe-delete-img"
            onClick={() => removeExercise(indexI, indexJ)}
          >
            <DeleteOutline
              sx={{ cursor: 'pointer', '&:hover': { color: 'error.main' } }}
            />
          </div>
        </Stack>

        <div className="exercise-ins-info" style={{ marginBottom: 10 }}>
          <img
            style={{ borderRadius: 10 }}
            className="img-fluid"
            src={
              exercise?.avatar ||
              'https://performex-images.s3.us-west-2.amazonaws.com/exercise.jpeg'
            }
            alt=""
          />

          <div className="exercise-ins-txt">
            <textarea
              placeholder="Enter any notes"
              className="form-control"
              value={[exercise?.Notes]}
              onChange={({ target: { value } }) =>
                onChangeNotes(value, indexI, indexJ)
              }
              style={{ height: 100, paddingLeft: 15, paddingTop: 10 }}
            ></textarea>
          </div>
        </div>
      </Stack>
    );
  };

  return (
    <div className="exercise-row-info exercise-row-info-cardios">
      {renderArrows()}
      {renderExerciseCard()}
      {isExpanded && (
        <ExerciseSetExpanded
          key={indexI + indexJ + 'expand'}
          indexI={indexI}
          indexJ={indexJ}
          exercise={exercise}
          handleSetsUpdate={handleSetsUpdate}
        />
      )}
    </div>
  );
}
