import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AuthContext } from '../../App';
import Loader from '../../context/Loader';
import { customFetch } from '../../Service/Api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useTrainerProfile = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const loader = useContext(Loader);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: auth.email,
      Name: auth.Name,
      organizationName: auth.organizationName,
      organizationDescription: auth.organizationDescription,
      accesscode: auth.accesscode,
      timezone: auth.timezone,
      weightUnit: auth.weightUnit,
      heightUnit: auth.heightUnit,
    },
  });

  const logOut = () => {
    localStorage.clear();
    navigate('/');
    window.location.reload();
  };

  const updateUserProfile = handleSubmit(async (data, event) => {
    event.preventDefault();

    try {
      loader.setLoading(true);

      const response = await customFetch(
        `${process.env.REACT_APP_API_URL}/api/users/updateTrainer/${auth._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': auth.token,
          },
          body: JSON.stringify(data),
        },
      );

      const responseJson = await response.json();
      if (responseJson.statusCode === 200) {
        const newAuthState = { ...auth, ...data };

        setAuth(newAuthState);
        localStorage.setItem('auth', JSON.stringify(newAuthState));

        toast.success(t('success.profileUpdated'));
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      loader.setLoading(false);
    }
  });

  return {
    control,
    register,
    logOut,
    onSubmit: updateUserProfile,
    errors,
    subscriptionStatus: auth.subscriptionStatus,
  };
};

export default useTrainerProfile;
