import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';

const MuiButton = ({
  children,
  fullWidth,
  sx,
  isLoading,
  transparent,
  danger,
  icon,
  ...props
}) => {
  const mainColor = danger ? 'error.main' : 'primary.main';

  return (
    <Box sx={fullWidth ? { width: 1 } : {}}>
      <Button
        {...props}
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          height: 43,
          bgcolor: transparent ? 'transparent' : mainColor,
          color: 'white',
          fontWeight: 600,
          flex: 1,
          borderRadius: 2.5,
          border: `1.5px solid`,
          borderColor: mainColor,
          minHeight: 42,
          px: 1.5,
          opacity: props.disabled ? 0.5 : 1,
          '&:hover': {
            bgcolor: danger ? 'error.hover' : 'primary.hover',
          },
          ...sx,
        }}
        disabled={isLoading || props.disabled}
      >
        <Stack
          direction="row"
          spacing={0.5}
          width={1}
          alignItems="center"
          justifyContent="center"
        >
          {!!isLoading && (
            <CircularProgress
              size={23}
              color="inherit"
              sx={{ position: 'absolute' }}
            />
          )}
          {icon && (
            <Box
              sx={{
                visibility: isLoading ? 'hidden' : 'visible',
                '& > *': { maxHeight: 20 },
              }}
            >
              {icon}
            </Box>
          )}
          <Typography
            fontSize={14}
            sx={{ visibility: isLoading ? 'hidden' : 'visible' }}
          >
            {children}
          </Typography>
        </Stack>
      </Button>
    </Box>
  );
};

export default MuiButton;
