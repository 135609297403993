import {
  HIT_USER_REQUEST,
  GET_USERS_DATA,
  FAILED_USER_DATA,
  UPDATE_USER,
  UPDATE_USER_STATUS,
  GET_USERS_PAGINATION,
} from '../constants';
import {
  activeToUsers,
  cancelInvite,
  deactiveToUsers,
  getAllUsers,
  updateUser,
} from '../../Service/Api';
import { toast } from 'react-toastify';
import i18n from '../../i18n.js';

export const editUser = (id, body, onSuccess) => async (dispatch) => {
  dispatch({ type: HIT_USER_REQUEST });

  try {
    const token = localStorage.getItem('token');
    let response = await updateUser(token, id, body);

    if (response && response.statusCode === 200) {
      toast.success(i18n.t('success.userUpdated'));
      onSuccess();
      dispatch({
        type: UPDATE_USER,
        payload: {
          data: response.data ?? [],
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({ type: FAILED_USER_DATA, error: err.message });
  }
};

export const getUsers =
  (filters = {}, sortBy, page, paginationFetch) =>
  async (dispatch) => {
    dispatch({ type: HIT_USER_REQUEST });

    try {
      const token = localStorage.getItem('token');
      let response = await getAllUsers(
        token,
        { ...filters, role: null },
        sortBy,
        page,
      );

      if (response && response.statusCode === 200) {
        dispatch({
          type: paginationFetch ? GET_USERS_PAGINATION : GET_USERS_DATA,
          payload: {
            data: response.data ?? [],
            page: response.page,
            lastPage: response.lastPage || 0,
          },
        });
      } else {
        throw response;
      }
    } catch (err) {
      dispatch({ type: FAILED_USER_DATA, error: err.message });
    }
  };

export const updateUserStatus = (ids, updateType) => async (dispatch) => {
  dispatch({ type: HIT_USER_REQUEST });
  try {
    const token = localStorage.getItem('token');
    let response = null;
    const body = { clients: ids };
    if (updateType === 'Active') {
      response = await deactiveToUsers(body, token);
    } else if (updateType === 'Pending') {
      const userId = body.users[0];
      response = await cancelInvite(userId, token);
    } else {
      response = await activeToUsers(body, token);
    }
    if (response && response.statusCode === 200) {
      dispatch({
        type: UPDATE_USER_STATUS,
        payload: {
          filtredIds: ids || [],
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_USER_DATA,
      payload: { error: err?.message ?? i18n.t('errors.somethingWentWrong') },
    });
  }
};
