import {
  BlockOutlined,
  CalendarMonth,
  ChatOutlined,
  HowToReg,
  InsightsOutlined,
  PersonOutlineSharp,
  Star,
  StarOutline,
} from '@mui/icons-material';
import { Badge, Icon } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/shared/Card';
import { useTranslation } from 'react-i18next';

const ClientListItem = ({
  user,
  setClientIdForChat,
  setClientIdForStatus,
  setClientIdForIsAdmin,
  hasNewChatMessages,
  getStatusChangeText,
  getIsAdminChangeText,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CustomIcon = ({ Icon, sx = {} }) => (
    <Icon
      sx={{
        width: 35,
        height: 35,
        color: 'silver',
        '&:hover': { color: 'white' },
        ...sx,
      }}
    />
  );

  const getStatusChangeIcon = (status) => {
    switch (status) {
      case 'Active':
        return BlockOutlined;
      default:
        return HowToReg;
    }
  };

  const isUserMyClient =
    JSON.parse(localStorage.getItem('auth'))._id === user.coach;

  const getIsAdminChangeIcon = (isAdmin) => {
    switch (isAdmin) {
      case true:
        return Star;
      default:
        return StarOutline;
    }
  };

  return (
    <Card title={user.Name} avatar={user.avatar} isCoach={user.coach}>
      <div className="images-chart">
        <div
          className="tooltip-icons-cstm"
          onClick={() => navigate(`/client/profile/${user._id}`)}
        >
          <CustomIcon
            Icon={PersonOutlineSharp}
            sx={{ mb: -0.5, mr: -0.5, height: 38, width: 38 }}
          />
          <span className="tooltiptext">{t('tooltips.profile')}</span>
        </div>
        {setClientIdForChat && isUserMyClient && (
          <Badge color="warning" variant={hasNewChatMessages ? 'dot' : 'none'}>
            <div
              onClick={() => setClientIdForChat(user._id)}
              className="tooltip-icons-cstm"
            >
              <CustomIcon Icon={ChatOutlined} sx={{ mb: -0.5 }} />
              <span className="tooltiptext">{t('tooltips.chat')}</span>
            </div>
          </Badge>
        )}
        {isUserMyClient && (
          <div
            className="tooltip-icons-cstm"
            onClick={() => navigate(`/client/calendars/${user._id}`)}
          >
            <CustomIcon Icon={CalendarMonth} />
            <span className="tooltiptext">{t('tooltips.calendar')}</span>
          </div>
        )}

        {isUserMyClient && (
          <div
            className="tooltip-icons-cstm"
            onClick={() => navigate(`/statistics?userId=${user._id}`)}
          >
            <CustomIcon Icon={InsightsOutlined} />
            <span className="tooltiptext">{t('tooltips.chart')}</span>
          </div>
        )}

        {setClientIdForStatus && (
          <div
            className="tooltip-icons-cstm"
            onClick={() => setClientIdForStatus(user._id)}
          >
            <CustomIcon
              Icon={getStatusChangeIcon(user.status)}
              sx={{ ml: -1 }}
            />
            <span
              className="tooltiptext"
              style={
                user.status !== 'Deactive' ? { background: '#ff3333' } : {}
              }
            >
              {getStatusChangeText(user.status)}
            </span>
          </div>
        )}
        {getIsAdminChangeText && (
          <div
            className="tooltip-icons-cstm"
            onClick={() => {
              setClientIdForIsAdmin(user._id);
            }}
          >
            <CustomIcon
              Icon={getIsAdminChangeIcon(user.isAdmin)}
              sx={{ ml: -1, color: user.isAdmin ? '#f1ba4e' : 'silver' }}
            />
            <span
              className="tooltiptext"
              style={user.isAdmin ? { background: '#ff3333' } : {}}
            >
              {getIsAdminChangeText(user.isAdmin)}
            </span>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ClientListItem;
