const DEFAULT_METADATA_ITEM = {
  firstMetric: {
    type: 'reps',
    value: 10,
  },
  secondMetric: {
    type: 'weight',
    value: 5,
    unit: 'kg',
  },
  tempo: '3010',
  rest: '01:30',
};

const DEFAULT_METADATA = [
  DEFAULT_METADATA_ITEM,
  DEFAULT_METADATA_ITEM,
  DEFAULT_METADATA_ITEM,
];

export const getDefaultMetadata = () => {
  const { weightUnit, heightUnit } = JSON.parse(localStorage.getItem('auth'));
  const result = DEFAULT_METADATA.map((item) => {
    let secondMetric = item.secondMetric;
    if (secondMetric.type === 'weight') {
      secondMetric = {
        ...secondMetric,
        unit: weightUnit,
      };
    }
    if (secondMetric.type === 'height') {
      secondMetric = {
        ...secondMetric,
        unit: heightUnit,
      };
    }
    return {
      ...item,
      secondMetric,
    };
  });
  return result;
};
