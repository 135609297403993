import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import store from '../redux/reducers/store';
import { NEW_NOTIFICATION_REQUEST } from '../redux/constants';

const firebaseConfig = {
  apiKey: 'AIzaSyAEhf2qVhzd6A3W1Ai-7AsTxuBP3OqBZwQ',
  authDomain: 'performex-coaching.firebaseapp.com',
  projectId: 'performex-coaching',
  storageBucket: 'performex-coaching.appspot.com',
  messagingSenderId: '270538845422',
  appId: '1:270538845422:web:c30e8363191f16ca8457ff',
  measurementId: 'G-MX14RK5X23',
};

const app = initializeApp(firebaseConfig);

export const message = getMessaging(app);

onMessage(message, (payload) => {
  handlePayload(payload);

  const data = {
    title: payload.notification.title,
    options: {
      body: payload.notification.title,
    },
  };
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function (serviceWorker) {
      serviceWorker.showNotification(data.title, data.options);
    });
  }
});

export const handlePayload = (payload) => {
  store.dispatch({ type: NEW_NOTIFICATION_REQUEST });
};
