import { DeleteOutline } from '@material-ui/icons';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { handleTimeFormat } from '../../utils';

export default function ExpandedInputs({
  index,
  data,
  exerciseMetadata,
  handleUpdate,
  handleRemoveSet,
}) {
  const [inputsData, setInputsData] = useState(null);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setInputsData({
        firstMetric: data?.firstMetric,
        secondMetric: data?.secondMetric,
        tempo: data?.tempo,
        rest: data?.rest,
      });
    }
  }, [data]);

  const handleOnChange = (name, value, isTime) => {
    let formattedValue = value;
    if (isTime) {
      if (value.type) formattedValue.value = handleTimeFormat(value.value);
      else formattedValue = handleTimeFormat(value);
    }
    if (formattedValue.value === '0' || parseInt(formattedValue.value) < 0) {
      formattedValue.value = '1';
    }
    const newValues = { ...inputsData, [name]: formattedValue };
    setInputsData(newValues);

    for (let i = index; i < exerciseMetadata.length; i++) {
      handleUpdate(newValues, i);
    }
  };

  if (!inputsData) {
    return <></>;
  }
  return (
    <tr key={index + 1}>
      <td width="10%">
        <span className="bg-clr-black-box">{index + 1}</span>
      </td>
      <td width="20%">
        <input
          type={inputsData.firstMetric.type === 'time' ? 'string' : 'number'}
          name="firstMetric"
          onChange={(e) =>
            handleOnChange(
              e.target.name,
              {
                ...inputsData.firstMetric,
                value: e.target.value,
              },
              inputsData.firstMetric.type === 'time',
            )
          }
          value={inputsData.firstMetric.value}
        />
      </td>
      <td width="20%">
        <input
          type={inputsData.secondMetric.type === 'time' ? 'string' : 'number'}
          name="secondMetric"
          onChange={(e) =>
            handleOnChange(
              e.target.name,
              {
                ...inputsData.secondMetric,
                value: e.target.value,
              },
              inputsData.secondMetric.type === 'time',
            )
          }
          value={inputsData.secondMetric.value}
        />
      </td>
      <td width="20%">
        <input
          type="number"
          name="tempo"
          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          value={inputsData.tempo}
        />
      </td>
      <td width="20%">
        <input
          type="text"
          name="rest"
          onChange={(e) => handleOnChange(e.target.name, e.target.value, true)}
          value={inputsData.rest}
        />
      </td>
      <td width="10%">
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{ height: 40, backgroundColor: '#2e313f', borderRadius: 10 }}
        >
          <DeleteOutline
            onClick={() => handleRemoveSet(index)}
            style={{
              height: 25,
              width: 25,
              color: 'silver',
              cursor: 'pointer',
            }}
            size={10}
          />
        </Stack>
      </td>
    </tr>
  );
}
