import { stringify } from '.././utils/index';
export const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
export const socketUrl = 'http://93.188.167.68:9999/chat';

export const customFetch = async (url, options) => {
  const response = await fetch(url, options);
  if (response.status === 403) {
    if (window.location.pathname !== '/subscription') {
      window.location.href = '/subscription';
    }
  } else {
    return response;
  }
};

export const addEvent = async (body) => {
  if (Object.keys(body).length <= 0) console.error('body is empty');
  // * options of fetch
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: stringify(body),
  };

  const response = await customFetch(`${baseUrl}/events/create`, options);
  const data = await response.json();
  return data;
};
export const getEventsByClient = async (token, trainerId, clientId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/events/getbyuser/${trainerId}/${clientId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const updateSessionExercises = async (body, token, id) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/sessions/updateexercise/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};

export const updateSessionData = async (body, token, id) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(`${baseUrl}/sessions/${id}`, options);
  const data = await response.json();
  return data;
};

export const DeleteExeriseData = async (sessionid, token, exerciseid) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/deleteExercise/${sessionid}/${exerciseid}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const addTeamEvent = async (body) => {
  if (Object.keys(body).length <= 0) console.error('body is empty');
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: stringify(body),
  };

  const response = await customFetch(`${baseUrl}/teamevents/create`, options);
  const data = await response.json();
  return data;
};
export const getEventsByTeam = async (token, trainerId, teamId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/teamevents/getbyTeam/${trainerId}/${teamId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getExercisesByUser = async (userId, token, query) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  try {
    let url = `${baseUrl}/exercises/getbyuser/${userId}?page=${
      query.page || 0
    }&language=${query.language}`;

    if (query.name) {
      url += `&name=${query.name}`;
    }
    const response = await customFetch(url, options);

    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const getUniqueExercisesAssginedByUser = async (userId, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  try {
    const response = await fetch(
      `${baseUrl}/exercises/unique-assigned/${userId}`,
      options,
    );
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const getSessionById = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await customFetch(
    `${baseUrl}/sessions/current/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const updateExcercieData = async (body, token, id) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };

  const response = await customFetch(
    `${baseUrl}/exercises/exerciseData/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const clientSession = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/clientsessions/create`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getClientSesssion = async (token, trainerId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/clientsessions/getbyuser/${trainerId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const TeamSession = async (body, trainerId, teamId) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: stringify(body),
  };
  const response = await customFetch(`${baseUrl}/teamsessions/create`, options);
  const data = await response.json();
  return data;
};

export const removeClientSession = async (clientSessionId, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/clientsessions/delete/${clientSessionId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const removeClientSessionExercise = async (
  sessionId,
  exerciseId,
  token,
) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/clientsessions/deleteExercise/${sessionId}/${exerciseId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getTeamSesssion = async (token, trainerId, teamId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/teamsessions/getbyuser/${trainerId}/${teamId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const updateClientSessionsExercise = async (body, id, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/clientsessions/updateexercise/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const deleteTeamExercise = async (id, exerciseId, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/teamsessions/deleteExercise/${id}/${exerciseId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const cancelInvite = async (id, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/users/delete/${id}`, options);
  const data = await response.json();
  return data;
};
export const updateTeamExercise = async (body, id, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/teamsessions/updateexercise/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const assignClientSessionApi = async (body) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/assignclientsessions/create`,
    options,
  );
  const data = await response.json();
  return data;
};

export const simulateTeamWorkout = async ({
  trainerId,
  clientId,
  teamId,
  assignedSessionId,
  workoutData,
  totalTime,
  simulationDate,
}) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify({
      trainerId,
      clientId,
      assignedSessionId,
      workoutData,
      totalTime,
      dueDate: new Date(),
      simulationDate,
      teamId,
    }),
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/create`,
    options,
  );
  const data = await response.json();
  return data;
};
export const simulateWorkout = async ({
  trainerId,
  clientId,
  assignedSessionId,
  workoutData,
  totalTime,
  simulationDate,
}) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify({
      trainerId,
      clientId,
      assignedSessionId,
      workoutData,
      totalTime,
      dueDate: new Date(),
      simulationDate,
    }),
  };
  const response = await customFetch(`${baseUrl}/workouts/create`, options);
  const data = await response.json();
  return data;
};
export const getAssignedClientSession = async (token, clientId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignclientsessions/getbyclient/${clientId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getAssignedTeamSession = async (token, teamId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/getbyteam/${teamId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const assignTeamSession = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/create`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getSessionCreatedList = async (token, trainerId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/getbyuser/${trainerId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getProgramList = async (token, trainerId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/programs/getbyuser/${trainerId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getAllUsers = async function (
  token,
  filterBy,
  orderBy = null,
  page = 0,
  pageSize,
) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify({ filterBy, orderBy }),
  };
  let url = `${baseUrl}/users/getUsers`;
  if (page !== null && page >= 0) {
    url += `?page=${page}`;
  }
  if (pageSize) {
    url += `${url.includes('?') ? '&' : '?'}pageSize=${pageSize}`;
  }
  const response = await customFetch(url, options);

  const data = await response.json();

  return data;
};
export const updateUser = async function (token, id, body) {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify(body),
  };
  const response = await customFetch(`${baseUrl}/users/update/${id}`, options);
  const data = await response.json();

  return data;
};
export const deleteUser = async function (token, id) {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/users/delete/${id}`, options);
  const data = await response.json();

  return data;
};
export const sendClientSignupEmail = async function (
  token,
  coachId,
  clientsEmails,
) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify({ userId: coachId, clientsEmails }),
  };
  const response = await customFetch(`${baseUrl}/emails/signup`, options);
  const data = await response.json();

  return data;
};
export const assignCoach = async function (token, clientEmail, coachId) {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify({ clientEmail, coachId }),
  };
  const response = await customFetch(`${baseUrl}/users/assignCoach`, options);
  const data = await response.json();

  return data;
};
export const createTeam = async function (token, body) {
  const resdata = await customFetch(`${baseUrl}/teams/create`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  }).then((res) => res.json());

  return resdata;
};
export const updateTeamById = async function (token, teamId, body) {
  const resdata = await customFetch(`${baseUrl}/teams/${teamId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

  return resdata;
};
export const uploadSessionPhoto = async (token, body, sessionId) => {
  const options = {
    method: 'PUT',
    headers: { 'x-access-token': token },
    body,
  };
  const response = await customFetch(
    `${baseUrl}/sessions/uploadProfilePic/${sessionId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const uploadTeamProfilePhoto = async (token, body, teamId) => {
  const options = {
    method: 'PUT',
    headers: { 'x-access-token': token },
    body,
  };
  const response = await customFetch(
    `${baseUrl}/teams/uploadProfilePic/${teamId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getFilteredTeams = async (
  token,
  filterBy = null,
  orderBy = null,
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify({ filterBy, orderBy }),
  };
  const response = await customFetch(
    `${baseUrl}/teams/filtered`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getTeamById = async (token, teamId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/teams/${teamId}`, options);
  const data = await response.json();
  return data;
};
export const deleteTeamById = async (token, teamId) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/teams/${teamId}`, options);
  const data = await response.json();
  return data;
};
export const getSessionByUser = async (token, trainerId, query) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  let url = `${baseUrl}/sessions/getbyuser/${trainerId}?page=${
    query.page || 0
  }`;
  if (query.name) {
    url += `&name=${query.name}`;
  }
  const response = await customFetch(url, options);
  const data = await response.json();
  return data;
};
export const sessionCreate = async (body, token) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(`${baseUrl}/sessions/create`, options);
  const data = await response.json();
  return data;
};

export const deleteSession = async (id, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/delete/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};

export const deleteSessionExcerise = async (id, exerciseId, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/deleteExercise/${id}/${exerciseId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const supersetAdd = async (token, sessionId, body) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/sessions/addSuperset/${sessionId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const removeSuperSet = async (token, sessionId, exerciseId) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/removeSuperset/${sessionId}/${exerciseId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const removeAllExercises = async (token, id) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await customFetch(
    `${baseUrl}/sessions/deleteAll/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};

export const deactiveToUsers = async (body, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/users/deactiveClients`,
    options,
  );
  const data = await response.json();
  return data;
};
export const activeToUsers = async (body, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(`${baseUrl}/users/activeClients`, options);
  const data = await response.json();
  return data;
};
export const updateAssignSession = async (body, id, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/assignclientsessions/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const rescheduleSession = async (body, id, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await fetch(
    `${baseUrl}/assignteamsessions/${id}/reschedule`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getSessionByDate = async (userId, date, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/getbyDate/${userId}/${date}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const orderUpExcercise = async (token, id, exerciseId) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/changeUpPosition/${id}/${exerciseId}`,
    options,
  ).then((data) => data.json());
  return response;
};
export const orderDownExcercise = async (token, id, exerciseId) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/sessions/changeDownPosition/${id}/${exerciseId}`,
    options,
  ).then((data) => data.json());
  return response;
};
export const getOldChat = async (roomId, page, PageSize, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const url = `${baseUrl}/conversations/getOldChat?room_id=${roomId}&pageNo=${page}&pageSize=${PageSize}`;
  const response = await customFetch(url, options).then((val) => val.json());
  return response;
};
export const getNotifications = async (page = 0, clientId) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  let url = `${baseUrl}/notifications?page=${page}`;
  if (clientId) {
    url += '&clientId=' + clientId;
  }
  const response = await customFetch(url, options).then((val) => val.json());
  return response;
};

export const getSubscriptionStatus = async () => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/payments/subscription`,
    options,
  ).then((data) => data.json());
  return response;
};

export const subscriptionCreate = async (body) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/payments/addSubscription`,
    options,
  ).then((data) => data.json());
  return response;
};
export const getStripeCoupon = async (couponId) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/payments/coupon/${couponId}`,
    options,
  ).then((data) => data.json());
  return response;
};
export const getUserById = async (id, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/users/currentUser/${id}`,
    options,
  ).then((data) => data.json());
  return response;
};
export const getUsersByIds = async (ids, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/users?ids=${ids.join(',')}`,
    options,
  ).then((data) => data.json());
  return response;
};
export const programGetById = async (id, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/programs/current/${id}`,
    options,
  ).then((data) => data.json());
  return response;
};
export const updateProgram = async (id, token, body) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(`${baseUrl}/programs/${id}`, options).then(
    (data) => data.json(),
  );
  return response;
};
export const exerciseById = async (id, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/programs/${id}`, options).then(
    (data) => data.json(),
  );
  return response;
};
export const updateProgramWeek = async (id, weekId, body, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/programs/updateweekSession/${id}/${weekId}`,
    options,
  ).then((data) => data.json());
  return response;
};
export const clientAssignedSessionByDate = async (
  token,
  trainerId,
  userId,
  date,
) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await customFetch(
    `${baseUrl}/assignclientsessions/getbyDate/${trainerId}/${userId}/${date}`,
    options,
  );

  const data = await response.json();
  return data;
};

export const importExercises = async (token, body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };

  const response = await customFetch(`${baseUrl}/exercises/import`, options);
  const data = await response.json();
  return data;
};

export const updateclientAssignedSession = async (
  token,
  body,
  date,
  trainerId,
  clientId,
  exerciseId,
) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };

  const response = await customFetch(
    `${baseUrl}/exercises/updateExerData/${trainerId}/${clientId}/${exerciseId}/${date}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getWorkoutDataByMonths = async (token, query) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await fetch(
    `${baseUrl}/workouts/getByMonths${query}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getWorkoutDataByDate = async (token, query) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await fetch(
    `${baseUrl}/workouts/getByDate${query}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getWorkoutsByClientIdAndDate = async (
  token,
  clientId,
  date,
  query,
) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await fetch(
    `${baseUrl}/workouts/client/${clientId}/${date.replaceAll('/', '-')}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getWorkoutsByTeamIdAndDate = async (
  token,
  teamId,
  date,
  query,
) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };

  const response = await fetch(
    `${baseUrl}/workouts/team/${teamId}/${date.replaceAll('/', '-')}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const update_assigned_exercise = async (token, url, body) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };

  const response = await customFetch(`${baseUrl}/${url}`, options);
  const data = await response.json();
  return data;
};
export const getAssignedCleintSessionById = async (id, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignclientsessions/current/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const team_assigned_session = async (id, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/getbyteam/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const team_assigned_session_byId = async (token, id) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/current/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const team_assigned_session_byDate = async (
  token,
  date,
  team,
  trainer,
) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/getbyDate/${trainer}/${team}/${date}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const update_team_session_date = async (token, id, body) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getNotificationsByClient = async (clientId, token) => {
  // /users/notificationGetByClient/{id}
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/users/notificationGetByClient/${clientId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const searchNotiByClinet = async (query) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  };
  const response = await customFetch(
    `${baseUrl}/users/notificationsearchByClient${query}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const deleteAssignedSessionById = async (id, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignclientsessions/delete/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const copy_assignedSession = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/assignclientsessions/copycreate`,
    options,
  );
  const data = await response.json();
  return data;
};
export const deleteTeamSession = async (id, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/delete/${id}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const copy_TeamAssignedSession = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/assignteamsessions/copycreate`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getPaymentInfo = async (token, userId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/payments/getByUser/${userId}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const bankCardByUser = async () => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/cards/getbyuser/`, options);
  const data = await response.json();
  return data;
};
export const deleteBankCard = async (id) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/cards/delete/${id}`, options);
  const data = await response.json();
  return data;
};
export const updateBankCard = async (id, body, token) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify(body),
  };
  const response = await customFetch(`${baseUrl}/cards/${id}`, options);
  const data = await response.json();
  return data;
};
export const createBankCard = async (body) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify(body),
  };
  const response = await customFetch(`${baseUrl}/cards/create`, options);
  const data = await response.json();
  return data;
};
export const setDefaultCard = async (body) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: JSON.stringify(body),
  };
  const response = await customFetch(`${baseUrl}/cards/default`, options);
  const data = await response.json();
  return data;
};

export const getSessionFeeds = async (token, recieverID) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/users/sessionfeedGetbyuser/${recieverID}`,
    options,
  );
  const data = await response.json();
  return data;
};
export const getAllPrices = async (token, abort) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/prices`, options, abort);
  const data = await response.json();
  return data;
};
export const login = async (body, abort) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      //  "x-access-token": token,
    },
    body: stringify({ ...body, type: 'trainer' }),
  };
  const response = await customFetch(`${baseUrl}/users/login`, options, abort);
  const data = await response.json();
  return data;
};
export const getClientOfTrainer = async (id, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/teams/getuserBytrainer/${id}`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};
export const cancelSubscription = async (id, token) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/payments/unSubscription/${id}`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};
export const userQuery = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: stringify(body),
  };
  const response = await customFetch(`${baseUrl}/query`, options);
  const data = await response.json();
  return Promise.resolve(data);
};
export const signup = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: stringify(body),
  };
  const response = await customFetch(`${baseUrl}/users/create`, options);
  const data = await response.json();
  return Promise.resolve(data);
};

export const getActiveClients = async () => {
  const { _id } = JSON.parse(localStorage.getItem('auth'));
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/users/activeClients/${_id}`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};

export const getActiveAndPendingClients = async (id, token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/users/activeAndPendingClients/${id}`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};

export const getWorkoutById = async (id) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/workouts/${id}`, options);
  const data = await response.json();
  return Promise.resolve(data);
};

export const getUnreadNotificationsCount = async () => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/notifications/unread`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};

export const getUnreadChatMessagesCount = async () => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/notifications/unreadMessages`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};

export const markNotificationsAsRead = async () => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(
    `${baseUrl}/notifications/markAsRead`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};
export const markChatAsRead = async (body) => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
    body: stringify(body),
  };
  const response = await customFetch(
    `${baseUrl}/notifications/markChatAsRead`,
    options,
  );
  const data = await response.json();
  return Promise.resolve(data);
};

export const createPaymentIntent = async () => {
  const token = localStorage.getItem('token');
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  };
  const response = await customFetch(`${baseUrl}/payments/intent`, options);
  const data = await response.json();
  return Promise.resolve(data);
};
