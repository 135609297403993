import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import MuiModal from '../shared/MuiModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const InfoSection = ({ label, value, hideDivider }) => (
  <Stack
    sx={{
      borderRight: hideDivider
        ? undefined
        : '2px solid rgba(255, 255, 255, 0.12)',
      pr: 2,
      mr: 2,
    }}
  >
    <Typography fontSize={12} sx={{ color: 'primary.main' }}>
      {label}
    </Typography>
    <Typography>{value}</Typography>
  </Stack>
);

const FinishedWorkoutsModal = ({
  workoutsData,
  onClose,
  isOpen,
  handleSubmit,
}) => {
  const { clients, workouts, totalReps, totalWeight } = workoutsData;
  const { t } = useTranslation();
  const formatAddingZeros = (n) => {
    return n > 9 ? '' + n : '0' + n;
  };

  const getDuration = (workout) => {
    const hours = workout?.totalTime?.hours;
    const minutes = workout?.totalTime?.minutes;
    const seconds = workout?.totalTime?.seconds;

    return `${formatAddingZeros(hours)}:${formatAddingZeros(
      minutes,
    )}:${formatAddingZeros(seconds)}`;
  };

  const getTotalReps = (workout) => {
    return workout.workoutData.reduce(
      (total, data) =>
        total +
        data.workout.reduce((total, workoutData) => {
          const valueFirstMetric =
            workoutData.firstMetric.type === 'reps'
              ? Number(workoutData.firstMetric.value) || 0
              : 0;
          const valueSecondMetric =
            workoutData.secondMetric.type === 'reps'
              ? Number(workoutData.secondMetric.value) || 0
              : 0;
          return total + valueFirstMetric + valueSecondMetric;
        }, 0),
      0,
    );
  };

  const getTotalWeight = (workout) => {
    const total = workout.workoutData.reduce(
      (total, data) =>
        total +
        data.workout.reduce((total, workoutData) => {
          const valueFirstMetric =
            workoutData.firstMetric.type === 'weight'
              ? Number(workoutData.firstMetric.value) || 0
              : 0;
          const valueSecondMetric =
            workoutData.secondMetric.type === 'weight'
              ? Number(workoutData.secondMetric.value) || 0
              : 0;
          return total + valueFirstMetric + valueSecondMetric;
        }, 0),
      0,
    );

    return `${total} ${totalWeight.unit}`;
  };

  return (
    <MuiModal
      onClose={onClose}
      open={isOpen}
      sx={{ width: '80%', maxWidth: 1000 }}
      sxButtons={{ mr: 1 }}
      onSubmit={handleSubmit}
      submitBtnText="Import"
      containerSx={{ overflow: undefined }}
    >
      <Stack spacing={2}>
        <Stack
          bgcolor="Background.dark"
          direction="row"
          borderRadius={2.5}
          p={2}
          sx={{ border: '1px solid white' }}
          justifyContent="space-around"
        >
          {totalReps && (
            <Stack direction="row" spacing={1} alignItems="end">
              <Typography fontSize={28} sx={{ lineHeight: 1.15 }}>
                {totalReps}
              </Typography>
              <Typography color="primary.main">
                {t('texts.totalReps')}
              </Typography>
            </Stack>
          )}

          {totalReps && totalWeight.unit && (
            <Box sx={{ borderRight: '1px solid white' }} />
          )}

          {totalWeight.unit && (
            <Stack direction="row" spacing={1} alignItems="end">
              <Typography fontSize={28} sx={{ lineHeight: 1.15 }}>
                {`${totalWeight.value} ${totalWeight.unit}`}
              </Typography>
              <Typography color="primary.main">
                {t('texts.totalWeight')}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack spacing={2} sx={{ overflow: 'auto', height: '70vh', pr: 2 }}>
          {workouts.map((workout) => {
            return (
              <Stack
                bgcolor="background.light"
                spacing={2}
                p={2}
                sx={{
                  borderRadius: 2.5,
                  border: '1.5px solid rgba(0,0,0,0.6)',
                }}
              >
                <Stack direction="row">
                  <InfoSection
                    label={t('texts.date')}
                    value={moment(new Date(workout.dueDate)).format(
                      'MM/DD/YYYY hh:mm A',
                    )}
                  />
                  <InfoSection
                    label={t('texts.duration')}
                    value={getDuration(workout)}
                  />
                  {totalReps && (
                    <InfoSection
                      label={t('texts.reps')}
                      value={getTotalReps(workout)}
                    />
                  )}
                  {totalWeight.unit && (
                    <InfoSection
                      label={t('texts.volume')}
                      value={getTotalWeight(workout)}
                    />
                  )}
                  {clients && (
                    <Typography sx={{ float: 'right', marginLeft: 'auto' }}>
                      {
                        clients.find(
                          (client) => client._id === workout.clientId,
                        ).Name
                      }
                    </Typography>
                  )}
                </Stack>

                <Divider w="100%" />

                <Grid container gap={1}>
                  {workout?.workoutData?.map((exercise, i) => {
                    return (
                      <Stack
                        key={'workout' + i}
                        bgcolor="rgba(0,0,0,0.3)"
                        p={1}
                        borderRadius={2.5}
                      >
                        <Typography fontSize={12} style={{ color: '#32B5FF' }}>
                          {exercise?.exerciseId?.ExerciseName}
                        </Typography>

                        <Typography fontSize={14}>
                          {`${exercise.workout.length || 0} sets`}
                          <span>
                            {exercise.workout.length && ' @'}{' '}
                            {exercise?.workout?.map((workoutData, k) => {
                              return (
                                <>
                                  <span>{workoutData.firstMetric.value}x</span>
                                  <span>
                                    {workoutData.secondMetric.value}
                                    {workoutData.secondMetric.unit}
                                    {k < exercise.workout.length - 1 && ', '}
                                  </span>
                                </>
                              );
                            })}
                          </span>
                        </Typography>
                      </Stack>
                    );
                  })}
                </Grid>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </MuiModal>
  );
};

export default FinishedWorkoutsModal;
