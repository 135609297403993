import { Save } from '@mui/icons-material';
import { Menu, MenuItem, Stack } from '@mui/material';
import MuiButton from '../../components/shared/MuiButton';
import SelectInput from '../../components/shared/SelectInput';
import { AddOutlined, MoreVertOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { SimulateWorkoutModal } from './SimulateWorkoutModal';
import useTeamMembers from '../teamMembers/useTeamMembers';
import PrintSessionViewModal from './PrintSessionViewModal';

export function SessionOptionsSection({
  isExistentSession,
  sessionSubmit,
  saveSessionToLibrary,
  handleWorkoutSimulation,
  setExercisesModalVisibility,
  setImportModalVisibility,
  selectedSessionId,
  setSelectedSessionId,
  sessions,
  resetState,
  teamId,
}) {
  const { t } = useTranslation();
  const { members } = useTeamMembers(teamId);
  const membersOptions = useMemo(
    () => members?.map((c) => ({ value: c._id, label: c.Name })) || [],
    [members],
  );

  const [printSessionModalOpened, setPrintSessionModalOpened] = useState(false);
  const [simulateWorkoutModalOpened, setSimulateWorkoutModalOpened] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const selectedSession = sessions?.find(
    (session) => session._id === selectedSessionId,
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptions = [
    { label: t('buttons.saveSessionToLibrary'), onClick: saveSessionToLibrary },
    {
      label: t('buttons.printSession'),
      onClick: () => setPrintSessionModalOpened(true),
    },
    {
      label: t('buttons.simulateWorkout'),
      onClick: () =>
        handleWorkoutSimulation() && setSimulateWorkoutModalOpened(true),
    },
    {
      label: t('buttons.addSession'),
      onClick: () => setImportModalVisibility(true),
    },
  ];

  return (
    <Stack spacing={2.5} alignItems="flex-end" width={1} mt={1}>
      <Stack
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        spacing={2}
        width={1}
      >
        {isExistentSession && selectedSessionId && (
          <Stack
            direction="row"
            gap={2}
            width="100%"
            alignItems="end"
            justifyContent="start"
            sx={{ maxWidth: 600 }}
          >
            <SelectInput
              label={t('texts.session')}
              value={selectedSessionId}
              onChange={(e) => setSelectedSessionId(e.target.value)}
              selectOptions={sessions.map((session) => ({
                value: session._id,
                label: session.title,
              }))}
              hideError={true}
              containerSx={{ mb: 0, flex: 1 }}
            />

            <MuiButton onClick={() => resetState(true)} icon={<AddOutlined />}>
              {t('buttons.createSession')}
            </MuiButton>
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="end"
          justifyContent="end"
          spacing={2}
          width={1}
        >
          <MuiButton
            onClick={() => setExercisesModalVisibility(true)}
            icon={<AddOutlined />}
          >
            {t('buttons.addExercise')}
          </MuiButton>

          {isExistentSession ? (
            <MuiButton onClick={() => sessionSubmit()} icon={<Save />}>
              {t('buttons.save')}
            </MuiButton>
          ) : (
            <MuiButton onClick={() => sessionSubmit()} icon={<Save />}>
              {t('buttons.create')}
            </MuiButton>
          )}

          <Stack>
            <MoreVertOutlined
              onClick={handleClick}
              style={{
                width: 35,
                height: 35,
                marginBottom: 3,
                cursor: 'pointer',
              }}
            />

            <Menu
              id="long-menu"
              MenuListProps={{ 'aria-labelledby': 'long-button' }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {menuOptions.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === 'Pyxis'}
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => {
                    option.onClick();
                    handleClose();
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Stack>
      </Stack>

      {selectedSession && (
        <PrintSessionViewModal
          session={selectedSession}
          opened={printSessionModalOpened}
          onClose={() => setPrintSessionModalOpened(false)}
        />
      )}
      <SimulateWorkoutModal
        opened={simulateWorkoutModalOpened}
        membersOptions={membersOptions}
        handleWorkoutSimulation={handleWorkoutSimulation}
        onClose={() => setSimulateWorkoutModalOpened(false)}
      />
    </Stack>
  );
}
