import { useEffect, useState } from 'react';
import { getDefaultMetadata } from '../../utils/constants';
import './ImportSessionModal.css';
import Loader from '../../pages/loader/Loader';
import RenderIf from '../../customComponents/RenderIf';
import { useDebounce } from '../../hooks/useDebounce';
import { getSessionByUser } from '../../Service/Api';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import MuiModal from '../shared/MuiModal';
import MuiButton from '../shared/MuiButton';
import { ExpandMoreOutlined } from '@material-ui/icons';

export default function ImportSessionModal({ isOpen, onClose, handleImport }) {
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(null);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    setLoading(true);
    const response = await getAvailableSessions(page, search);
    setSessions(response);
    setFilteredSessions(response);
    setLoading(false);
  };

  useEffect(() => {
    handleSearchUpdate();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (page > 0 && page <= lastPage) {
      handleNextPage();
    }
  }, [page]);

  const handleSearchUpdate = async () => {
    setLoading(true);
    if (search.trim()) {
      const response = await getAvailableSessions(0, search);
      setFilteredSessions(response);
      scrollTo(response[0]?._id);
    } else {
      setFilteredSessions([...sessions]);
      scrollTo([...sessions][0]?._id);
    }
    setPage(0);
    scrollTo();
    setLoading(false);
  };

  const scrollTo = (id) => {
    const item = document.getElementById(id);
    if (item) {
      item.scrollIntoView(true);
    }
  };

  const handleNextPage = async () => {
    setLoadingNextPage(true);
    const response = await getAvailableSessions(page, search);
    if (!search.trim()) {
      setSessions([...sessions, ...response]);
      setFilteredSessions([...sessions, ...response]);
    } else {
      setFilteredSessions([...filteredSessions, ...response]);
    }
    setLoadingNextPage(false);
  };

  const getAvailableSessions = async (page, search) => {
    const { _id } = JSON.parse(localStorage.getItem('auth'));
    const token = localStorage.getItem('token');
    const query = {
      name: search || '',
      page,
    };
    const response = await getSessionByUser(token, _id, query);
    if (response.statusCode === 200) {
      setLastPage(response.data.lastPage);
      return response.data.sessions;
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom) {
      setPage((p) => p + 1);
    }
  };

  const handleSubmit = () => {
    const exercisesWithMetadata = selectedSessions
      .map(({ exercises, exercisesMetadata }) =>
        exercises.map((superset) =>
          superset.map((exercise) => {
            return {
              ...exercise,
              metadata:
                exercisesMetadata.find(
                  ({ exerciseId }) => exerciseId === exercise._id,
                )?.metadata || getDefaultMetadata(),
            };
          }),
        ),
      )
      .reduce((result, sessionSuperset) => [...sessionSuperset, ...result]);

    handleImport(exercisesWithMetadata);
  };

  const confirmNavigation = () => {
    if (
      window.confirm(
        'Do you really want to exit? All unsaved changes will be lost.',
      )
    ) {
      window.location.href = '/librarypage/sessions';
    }
  };

  const renderSessionExercises = (exercises) => {
    return exercises.map((superset, supersetIndex) =>
      superset.map((exercise, exerciseIndex) => {
        const supersetExerciseNumber =
          superset.length > 1 ? exerciseIndex + 1 : '';
        const supersetLetter = alphabet[supersetIndex];
        const prefix = `${supersetLetter}${supersetExerciseNumber})`;

        return (
          <Stack
            key={exerciseIndex + exercise._id}
            direction="row"
            sx={{ mb: 1 }}
            spacing={1}
          >
            <Typography style={{ color: 'white' }}>{prefix}</Typography>
            <Typography sx={{ color: 'secondary.main' }}>
              {exercise.ExerciseName}
            </Typography>
          </Stack>
        );
      }),
    );
  };

  const handleSessionSelect = (session) => {
    const index = selectedSessions.findIndex((e) => e._id === session._id);
    const newSessions = [...selectedSessions];

    if (index >= 0) {
      newSessions.splice(index, 1);
    } else {
      newSessions.push(session);
    }

    setSelectedSessions(newSessions);
  };

  const isSessionSelected = (id) => {
    return selectedSessions.filter((e) => e._id === id).length > 0;
  };

  return (
    <MuiModal
      onClose={onClose}
      open={isOpen}
      sx={{ width: '80%', maxWidth: 1000 }}
      sxButtons={{ mr: 1 }}
      onSubmit={handleSubmit}
      submitBtnText="Import"
    >
      <Stack
        className="popup-2-btn"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <div className="has-search" style={{ flex: 1, flexGrow: 1 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search for session by name"
            style={{ backgroundColor: '#2E313F' }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <MuiButton sx={{ mr: 1 }} onClick={() => confirmNavigation()}>
          New session
        </MuiButton>
      </Stack>

      <Stack
        sx={{
          height: window.innerHeight * 0.65,
          overflow: 'auto',
          mt: 2,
          pr: 2,
        }}
        onScroll={handleScroll}
        spacing={2}
      >
        {filteredSessions?.map((session, index) => {
          return (
            <Accordion
              key={index}
              sx={{
                bgcolor: 'background.light',
                borderRadius: '10px !important',
                p: 1.5,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Checkbox
                    type="checkbox"
                    sx={{ color: 'white' }}
                    checked={isSessionSelected(session._id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleSessionSelect(session)}
                  />

                  <img
                    style={{ borderRadius: 10, height: 80 }}
                    className="img-fluid"
                    src={
                      session?.avatar?.length && !session.avatar.substr('aws')
                        ? session.avatar
                        : 'https://performex-images.fra1.digitaloceanspaces.com/performex-images/session-icon.jpeg'
                    }
                    alt=""
                  />
                  <Typography sx={{ fontSize: 21 }}>
                    {session.SessionName}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ py: 0 }}>
                <Box
                  sx={{
                    color: 'grey',
                    mb: 2,
                    borderBottom: 1,
                    width: '100%',
                  }}
                />
                <Box sx={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                  {renderSessionExercises(session.exercises)}
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}

        {filteredSessions.length === 0 && (
          <span style={{ color: 'white' }}>No sessions found.</span>
        )}

        <RenderIf isTrue={loading}>
          <Loader />
        </RenderIf>
      </Stack>

      <div style={{ textAlign: 'center', height: 30, marginTop: 10 }}>
        <RenderIf isTrue={loadingNextPage}>
          <CircularProgress
            size={23}
            color="primary"
            sx={{ position: 'static' }}
          />
        </RenderIf>
      </div>
    </MuiModal>
  );
}
