import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Box } from '@mui/material';
import { useState } from 'react';
import FormInput from './FormInput';
import { useTranslation } from 'react-i18next';

const PasswordInput = ({
  register,
  label,
  placeholder,
  name = 'password',
  variant,
  error,
  customErrorText,
  options,
  ...props
}) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false);
  const passwordRequirementsText = t('texts.passwordRequirements')

  const toggleShowPassword = () => {
    setShowPassword((e) => !e);
  };

  return (
    <FormInput
      type={showPassword ? 'text' : 'password'}
      register={register}
      label={label || t('texts.password')}
      placeholder={placeholder || t('palceholders.enterPassword')}
      name={name}
      variant={variant}
      error={error}
      options={
        options || {
          validate: (value) =>
            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/g.test(
              value,
            )
              ? true
              : customErrorText || passwordRequirementsText,
        }
      }
      endAdornment={
        <Box sx={{ cursor: 'pointer' }}>
          {showPassword ? (
            <VisibilityOff onClick={toggleShowPassword} />
          ) : (
            <Visibility onClick={toggleShowPassword} />
          )}
        </Box>
      }
      {...props}
    />
  );
};

export default PasswordInput;
