import { useEffect, useState } from 'react';
import { getDefaultMetadata } from '../../utils/constants';
import Loader from '../../pages/loader/Loader';
import RenderIf from '../../customComponents/RenderIf';
import { useDebounce } from '../../hooks/useDebounce';
import { getExercisesByUser } from '../../Service/Api';
import { Checkbox, CircularProgress, Stack, Typography } from '@mui/material';
import MuiModal from '../shared/MuiModal';
import MuiButton from '../shared/MuiButton';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SaveExercise from '../SaveExercise/SaveExercise';
import SelectInput from '../shared/SelectInput';

export default function SelectExercisesModal({
  isOpen,
  onClose,
  addExercises,
}) {
  const [language, setLanguage] = useState('English');
  const [exercises, setExercises] = useState([]);
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(null);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [openCreateExercise, setOpenCreateExercise] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) loadInitialData();
  }, [isOpen, language]);

  const handleClose = () => {
    onClose();
    setSearch('');
    setPage(0);
    setLastPage(null);
  };

  const loadInitialData = async () => {
    setLoading(true);
    const response = await getAvailableExercises(page, search);
    setExercises(response);
    setFilteredExercises(response);
    setLoading(false);
  };

  useEffect(() => {
    handleSearchUpdate();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (page > 0 && page <= lastPage) {
      handleNextPage();
    }
  }, [page]);

  const handleSearchUpdate = async () => {
    setLoading(true);
    if (search.trim()) {
      const response = await getAvailableExercises(0, search);
      setFilteredExercises(response);
      scrollTo(response[0]?._id);
    } else {
      setFilteredExercises([...exercises]);
      scrollTo([...exercises]?._id);
    }
    setPage(0);
    setLoading(false);
  };

  const scrollTo = (id) => {
    const item = document.getElementById(id);
    if (item) {
      item.scrollIntoView(true);
    }
  };

  const handleNextPage = async () => {
    setLoadingNextPage(true);
    const response = await getAvailableExercises(page, search);
    if (!search.trim()) {
      setExercises([...exercises, ...response]);
      setFilteredExercises([...exercises, ...response]);
    } else {
      setFilteredExercises([...filteredExercises, ...response]);
    }
    setLoadingNextPage(false);
  };

  const getAvailableExercises = async (page, search) => {
    const { _id } = JSON.parse(localStorage.getItem('auth'));
    const token = localStorage.getItem('token');
    const query = {
      language,
      name: search || '',
      page,
    };
    const response = await getExercisesByUser(_id, token, query);
    if (response.statusCode === 200) {
      setLastPage(response.data.lastPage);
      return response.data.exercises;
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom) {
      setPage((p) => p + 1);
    }
  };

  const handleExerciseSelect = (exercise) => {
    const index = selectedExercises.findIndex((e) => e._id === exercise._id);
    const newExercises = [...selectedExercises];

    if (index >= 0) {
      newExercises.splice(index, 1);
    } else {
      newExercises.push(exercise);
    }

    setSelectedExercises(newExercises);
  };

  const isExerciseSelected = (id) => {
    return selectedExercises.filter((e) => e._id === id).length > 0;
  };

  const handleSubmit = () => {
    if (openCreateExercise) {
      return;
    }
    if (!selectedExercises.length) {
      toast.info(t('validations.noExercisesSelected'));
      return;
    }
    const aux = [...selectedExercises];
    const result = [];

    for (let exercise of aux) {
      result.push([{ ...exercise, metadata: getDefaultMetadata() }]);
    }
    setSelectedExercises([]);
    addExercises(result);
  };

  return (
    <MuiModal
      onClose={handleClose}
      open={isOpen}
      sx={{ width: '80%', maxWidth: 1500 }}
      submitBtnText={t('buttons.addExercises')}
      onSubmit={handleSubmit}
    >
      <SaveExercise
        open={openCreateExercise}
        onClose={() => {
          setOpenCreateExercise(false);
        }}
        updateExercises={() => handleSearchUpdate()}
      />
      <Stack
        className="popup-2-btn"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        pt={1}
      >
        <SelectInput
          value={language}
          onChange={({ target: { value } }) => setLanguage(value)}
          label={t('texts.language')}
          sx={{ mt: 1.2, width: 150 }}
          selectOptions={['English', 'Swedish'].map((language) => ({
            value: language,
            label: language,
          }))}
        />
        <div className="has-search" style={{ flex: 1, flexGrow: 1 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search for exercise by name"
            style={{ backgroundColor: '#2E313F' }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <MuiButton sx={{ mr: 2 }} onClick={() => setOpenCreateExercise(true)}>
          {t('buttons.newExercise')}
        </MuiButton>
      </Stack>

      <Stack
        sx={{
          height: window.innerHeight * 0.65,
          overflow: 'auto',

          mt: 2,
        }}
        onScroll={handleScroll}
      >
        {filteredExercises
          ?.filter((exercise) =>
            language === 'English'
              ? !!exercise.ExerciseName
              : !!exercise.ExerciseNameSE,
          )
          .map((exercise, index) => {
            return (
              <Stack
                id={exercise._id}
                key={index}
                className="boxes bo-x"
                direction="row"
                spacing={3}
                alignItems="center"
                sx={{ m: 0, mt: 2, mr: 2, p: 3 }}
                style={{ backgroundColor: '#2E313F' }}
                onClick={() => handleExerciseSelect(exercise)}
              >
                <Checkbox
                  type="checkbox"
                  sx={{ color: 'white' }}
                  checked={isExerciseSelected(exercise._id)}
                  onChange={() => handleExerciseSelect(exercise)}
                />

                <img
                  style={{ borderRadius: 10, height: 80 }}
                  className="img-fluid"
                  src={
                    exercise?.avatar ||
                    'https://performex-images.fra1.digitaloceanspaces.com/performex-images/exercises-icon'
                  }
                  alt=""
                />

                <Stack>
                  <h4 className="font-size-22">
                    {language === 'English'
                      ? exercise.ExerciseName
                      : exercise.ExerciseNameSE}
                  </h4>
                  <Typography sx={{ color: 'secondary.main' }}>
                    {language === 'English'
                      ? exercise.Instructions
                      : exercise.InstructionsSE}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
        {filteredExercises.length === 0 && (
          <span style={{ color: 'white' }}>{t('texts.noExercises')}</span>
        )}
      </Stack>
      <RenderIf isTrue={loading}>
        <Loader />
      </RenderIf>
      <div style={{ textAlign: 'center', height: 30, marginTop: 10 }}>
        <RenderIf isTrue={loadingNextPage}>
          <CircularProgress
            size={23}
            color="primary"
            sx={{ position: 'static' }}
          />
        </RenderIf>
      </div>
    </MuiModal>
  );
}
