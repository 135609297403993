import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import FormInput from '../../../components/shared/FormInput';
import { Search } from '@mui/icons-material';
import useAllUsers from './useAllUsers';
import { useTranslation } from 'react-i18next';
import ClientListItem from '../../client/ClientListItem';
import FormSelectInput from '../../../components/shared/FormSelectInput';
import MuiModal from '../../../components/shared/MuiModal';
import Loader from '../../loader/Loader';
import Loading from '../../../wrappers/Loading';
import { CircleLoader } from 'react-spinners';

const AllUsers = () => {
  const { t } = useTranslation();
  const {
    onChangeUserStatus,
    setUserIdForStatusModal,
    onChangeUserIsAdmin,
    setUserIdForIsAdminModal,
    registerOptions,
    handleScroll,
    userIdForIsAdminModal,
    userIdForStatusModal,
    controlOptions,
    filterByRoleOptions,
    userStatusOptions,
    orderByOptions,
    usersData,
    selectedUser,
    loading,
  } = useAllUsers();

  const getStatusChangeText = (status) => {
    switch (status) {
      case 'Active':
        return t('texts.deactivate');
      case 'Deactive':
        return t('texts.activate');
      default:
        return t('texts.cancelInvite');
    }
  };

  const getIsAdminChangeText = (isAdmin) => {
    switch (isAdmin) {
      case true:
        return t('texts.revokeAdmin');
      default:
        return t('texts.makeAdmin');
    }
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <FormInput
          name="filterByName"
          register={registerOptions}
          placeholder={t('placeholders.searchByUserName')}
          startAdornment={<Search sx={{ color: 'secondary.main', ml: 2 }} />}
          sx={{ width: 300, mt: 0.5, mb: -1 }}
        />
        <FormSelectInput
          name="filterByStatus"
          control={controlOptions}
          label={t('texts.filterByStatus')}
          selectOptions={userStatusOptions}
          sx={{ width: 150 }}
          hideError={true}
        />
        <FormSelectInput
          name="orderBy"
          control={controlOptions}
          label={t('texts.orderBy')}
          selectOptions={orderByOptions}
          sx={{ width: 150 }}
        />
        <FormSelectInput
          name="filterByRole"
          control={controlOptions}
          label={t('texts.filterByRole')}
          selectOptions={filterByRoleOptions}
          sx={{ width: 150 }}
        />
      </Stack>

      <Stack
        alignItems="center"
        sx={{
          height: 'calc(100vh - 150px)',
          overflowY: 'auto',
          pr: 1,
          pb: 2,
        }}
        onScroll={handleScroll}
      >
        {!usersData?.data?.length && !loading ? (
          <Box sx={{ mt: 2, alignSelf: 'start !important' }}>
            <Typography>{t('texts.noUsersFound')}</Typography>
          </Box>
        ) : (
          usersData?.data?.map((user) => {
            return (
              <Box key={user._id} width="100%">
                <ClientListItem
                  hasNewChatMessages={user.newChatMessagesCount > 0}
                  user={user}
                  setClientIdForStatus={setUserIdForStatusModal}
                  setClientIdForIsAdmin={setUserIdForIsAdminModal}
                  getStatusChangeText={getStatusChangeText}
                  getIsAdminChangeText={getIsAdminChangeText}
                />
              </Box>
            );
          })
        )}
        {!!usersData?.data?.length && loading && <CircularProgress size={30} />}
        {usersData.page === usersData.lastPage && usersData.data.length >= 10 && <Typography color="grey">End of client's list</Typography>}

      </Stack>

      {selectedUser && (
        <>
          <MuiModal
            title={`${getStatusChangeText(selectedUser.status)}`}
            description={`${t(
              'modals.phrases.areYouSure',
            )} ${getStatusChangeText(selectedUser.status).toUpperCase()} ${t(
              'phrases.thisUser',
            )}`}
            open={!!userIdForStatusModal}
            onClose={() => setUserIdForStatusModal('')}
            onSubmit={onChangeUserStatus}
            submitBtnText={t('buttons.confirm')}
          />

          <MuiModal
            title={`${getIsAdminChangeText(selectedUser.isAdmin)}`}
            description={`${t(
              'modals.phrases.areYouSure',
            )} ${getIsAdminChangeText(selectedUser.isAdmin).toUpperCase()}?`}
            open={!!userIdForIsAdminModal}
            onClose={() => setUserIdForIsAdminModal('')}
            onSubmit={onChangeUserIsAdmin}
            submitBtnText={t('buttons.confirm')}
          />
        </>
      )}
    </Stack>
  );
};

export default AllUsers;
