import { MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';
import SelectInput from './SelectInput';

const FormSelectInput = ({ name, options, control, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={options}
      defaultValue={'None'}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <SelectInput
          ref={ref}
          onChange={onChange}
          value={value}
          error={error || props.error}
          {...props}
        />
      )}
    />
  );
};

export default FormSelectInput;
