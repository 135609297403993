import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Badge, Box, Stack, Typography } from '@mui/material';
import {
  SettingsOutlined,
  NotificationsOutlined,
  AddAPhoto,
  ChatOutlined,
  PeopleAltOutlined,
} from '@mui/icons-material';
import MuiModal from '../shared/MuiModal';
import useNavBar from './useNavBar';
import FormFileInput from '../shared/FormFileInput';
import { message } from '../../firebase/firebase';
import { setNotifications } from '../../redux/actions/Notification';
import { onMessage } from 'firebase/messaging';
import { getTeams } from '../../redux/actions/Teams';
import { getClients } from '../../redux/actions/Clients';
import { useTranslation } from 'react-i18next';
import SelectInput from '../shared/SelectInput';
import { AuthContext } from '../../App';
import { SIDEBAR_WIDTH } from '../sidebar/Sidebar';
import LanguageDropdown from '../shared/LanguageDropdown';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const NavBar = () => {
  const {
    register,
    errors,
    profilePhotoModal,
    setProfilePhotoModal,
    ProgramProfileUpload,
    currentUser,
    isLoading,
  } = useNavBar();

  const Noti = useSelector((state) => state.notification);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const mainPagesPaths = [
    '/librarypage',
    '/statistics',
    '/team',
    '/client',
    '/home',
  ];
  const showBackArrow = !mainPagesPaths.includes(location.pathname);

  useEffect(() => {
    const unsubscribeNotificationReceived = onMessage(
      message,
      async (remoteMessage) => {
        if (location.pathname === '/notification') {
          dispatch(setNotifications());
        } else if (
          location.pathname === '/client' &&
          remoteMessage.data.type === 'CHAT_MESSAGE'
        ) {
          dispatch(getClients({ coachId: currentUser._id }));
        } else if (
          location.pathname === '/team' &&
          remoteMessage.data.type === 'TEAM_MESSAGE'
        ) {
          dispatch(getTeams({ ...(currentUser.isAdmin ? {} : { trainerId: currentUser._id })}));
        } else if (remoteMessage.data.type === 'SUBSCRIPTION_CONFIRMED') {
          const authCopy = { ...auth };
          authCopy.subscriptionStatus = 'ACTIVE';
          localStorage.setItem('auth', JSON.stringify(authCopy));
          window.location.href = '/client';
        } else if (remoteMessage.data.type === 'SUBSCRIPTION_REMOVED') {
          const authCopy = { ...auth };
          authCopy.subscriptionStatus = 'NOT_ACTIVE';
          localStorage.setItem('auth', JSON.stringify(authCopy));
          window.location.href = '/subscription';
        }
      },
    );
    return () => unsubscribeNotificationReceived();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: 75,
        mb: 2,
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          pr: 3,
          pb: 2.5,
          width: '100%',
          bgcolor: 'background.darker',
          zIndex: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{ width: '100%', height: 38, mt: 3 }}
          justifyContent={showBackArrow ? 'space-between' : 'end'}
          alignItems="center"
        >
          {showBackArrow && (
            <div
              style={{
                cursor: 'pointer',
                padding: 10,
                marginLeft: SIDEBAR_WIDTH,
                position: 'fixed',
                left: 20,
              }}
              onClick={() => navigate(-1)}
            >
              <img src="/assets/arrow-left-img.png" alt="fvg" />
            </div>
          )}

          <Box className="noti-icons" sx={{ position: 'fixed', right: 24 }}>
            <LanguageDropdown />

            <Badge color="warning" variant={Noti.newNotify ? 'dot' : 'none'}>
              <NotificationsOutlined
                fontSize="medium"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/notification');
                }}
              />
            </Badge>

            <SettingsOutlined
              onClick={() => navigate('/settings')}
              sx={{ cursor: 'pointer' }}
              fontSize="medium"
            />

            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>{currentUser.Name?.split(' ')?.[0]}</Typography>
              <Badge color="success" variant="dot">
                <Avatar
                  onClick={() => setProfilePhotoModal(true)}
                  sx={{
                    width: 60,
                    height: 60,
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  src={currentUser.avatar || '/broken-image.jpg'}
                >
                  <AddAPhoto fontSize="medium" />
                </Avatar>
              </Badge>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <MuiModal
        title={t('texts.changeProfilePhoto')}
        submitBtnText={t('buttons.change')}
        closeBtnText={t('buttons.close')}
        open={profilePhotoModal}
        onClose={() => setProfilePhotoModal(false)}
        onSubmit={ProgramProfileUpload}
        isLoading={isLoading}
      >
        <Box sx={{ py: 2 }}>
          <FormFileInput
            name="profilePhoto"
            register={register}
            error={errors.profilePhoto}
            options={{ required: t('validations.photoRequired') }}
          />
        </Box>
      </MuiModal>
    </Box>
  );
};

export default NavBar;
