import React, { useState, useEffect } from 'react';
import './Session.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RenderIf from '../../customComponents/RenderIf';
import Loader from '.././loader/Loader';
import { useDebounce } from '../../hooks/useDebounce';
import { Box, CircularProgress, Stack } from '@mui/material';
import { customFetch } from '../../Service/Api';
import MuiButton from '../../components/shared/MuiButton';
import { AddOutlined } from '@material-ui/icons';
import MuiModal from '../../components/shared/MuiModal';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import FormInput from '../../components/shared/FormInput';
import { useForm } from 'react-hook-form';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Session = () => {
  let navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [sessionId, setSessionId] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [page, setPage] = useState(0);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const { t } = useTranslation();

  const { register, watch, reset } = useForm({ defaultValues: { search: '' } });
  const search = watch('search');

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (page > 0 && page <= lastPage) {
      handleNextPage();
    }
  }, [page]);

  useEffect(() => {
    handleSearchUpdate();
  }, [debouncedSearchTerm]);

  const loadInitialData = async () => {
    setLoading(true);
    const response = await getSessions(0, '');
    setPage(0);
    reset();
    setSessions([...response]);
    setFilteredSessions([...response]);
    setLoading(false);
  };

  const profileOpen = () => {
    setOpenProfile(true);
  };
  const profileClose = () => {
    setOpenProfile(false);
  };

  const getSessionId = (id) => {
    setSessionId(id);
  };

  const getSessions = async (page, search) => {
    const items = JSON.parse(localStorage.getItem('auth'));
    let trainerId = items._id;
    let token = localStorage.getItem('token');

    const query = {
      name: search || '',
      page,
    };
    let url = `${process.env.REACT_APP_API_URL}/api/sessions/getbyuser/${trainerId}?page=${query.page}`;
    if (query.name) {
      url += `&name=${query.name}`;
    }
    return customFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.statusCode === 200) {
          setLastPage(response.data.lastPage);
          return response.data.sessions;
        } else {
          // toast(resdata.error)
        }
      });
  };

  const handleNextPage = async () => {
    setLoadingNextPage(true);
    const response = await getSessions(page, search);
    if (!search.trim()) {
      setSessions([...sessions, ...response]);
      setFilteredSessions([...sessions, ...response]);
    } else {
      setFilteredSessions([...filteredSessions, ...response]);
    }
    setLoadingNextPage(false);
  };

  const handleSearchUpdate = async () => {
    setLoading(true);
    if (search.trim()) {
      const response = await getSessions(0, search);
      setFilteredSessions(response);
      scrollTo(response[0]?._id);
    } else {
      setFilteredSessions([...sessions]);
      scrollTo([...sessions][0]?._id);
    }
    setPage(0);
    setLoading(false);
  };

  const scrollTo = (id) => {
    const item = document.getElementById(id);
    if (item) {
      item.scrollIntoView(true);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom) {
      setPage((p) => p + 1);
    }
  };

  const DeleteSession = () => {
    let token = localStorage.getItem('token');
    customFetch(
      `${process.env.REACT_APP_API_URL}/api/sessions/delete/${sessionId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      },
    )
      .then((res) => res.json())
      .then((resdata) => {
        if (resdata.statusCode === 200) {
          toast.success(t('success.sessionDeleted'));
          loadInitialData(); // TODO RESET PAGE
        } else {
          toast.error(resdata.error);
        }
      })
      .finally(() => {
        setDeleteModalVisibility(false);
      });
  };
  // end

  // New Session upload profile picture
  const SessionUploadImage = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      let formData = new FormData();
      formData.append('image', image);
      const response = await customFetch(
        `${process.env.REACT_APP_API_URL}/api/sessions/uploadProfilePic/${sessionId}`,
        {
          method: 'PUT',
          body: formData,
          headers: {
            'x-access-token': localStorage.getItem('token'),
          },
        },
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        toast.success(data.message);
        setOpenProfile(false);
        loadInitialData(); // TO DO RESET PAGE
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };
  // end

  return (
    <React.Fragment>
      <RenderIf isTrue={loading}>
        <Loader />
      </RenderIf>

      <RenderIf isTrue={!loading}>
        <div className="client-page">
          <div className="client-nav">
            <div>
              <ul className="nav">
                <li className="nav-item">
                  <NavLink to="/librarypage">
                    <span>{t('texts.exercises')}</span>
                  </NavLink>
                </li>
                <li className="nav-item exercise active">
                  <NavLink to="/librarypage/sessions">
                    <span>{t('texts.sessions')}</span>
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/librarypage/programmm">
                    <span>Program</span>
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
          <Stack
            className="search"
            direction="row"
            justifyContent="space-between"
            alignItems="end"
            sx={{ mt: 1, mb: 1 }}
          >
            <FormInput
              name="search"
              register={register}
              placeholder={t('placeholders.searchBySessionName')}
              sx={{ width: 350 }}
              startAdornment={
                <Search sx={{ color: 'secondary.main', ml: 2 }} />
              }
            />

            <MuiButton
              sx={{ mb: 1.2 }}
              onClick={() => navigate('/librarypage/session/create')}
              icon={<AddOutlined />}
            >
              {t('buttons.addSession')}
            </MuiButton>
          </Stack>

          <div
            onScroll={handleScroll}
            style={{ overflow: 'auto', height: 'calc(100vh - 220px)' }}
          >
            {filteredSessions.map((session) => {
              return (
                <div
                  id={session._id}
                  key={session._id}
                  className="boxes"
                  style={{
                    marginLeft: 0,
                    padding: 25,
                    paddingRight: 30,
                    alignItems: 'center',
                  }}
                >
                  <div
                    onClick={() =>
                      navigate(`/librarypage/session/${session?._id}`)
                    }
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      style={{ borderRadius: 10, height: 80 }}
                      src={
                        session?.avatar?.length && !session.avatar.substr('aws')
                          ? session.avatar
                          : 'https://performex-images.fra1.digitaloceanspaces.com/performex-images/session-icon.jpeg'
                      }
                      alt=""
                      onClick={() => {
                        profileOpen();
                        getSessionId(session?._id);
                      }}
                    />

                    <div className="box-dataupper">
                      <h3>
                        <span>{session.SessionName}</span>
                      </h3>
                      <p style={{ color: '#aaa' }}>{session.Instructions}</p>
                      <span
                        style={{
                          display:
                            session.createdBy === 'admin' ? 'none' : 'block',
                          fontSize: 12,
                          opacity: 0.5,
                        }}
                      >
                        {t('texts.createdByYou')}
                      </span>
                    </div>
                  </div>
                  <div className="images-chart">
                    <img
                      src="/assets/close.png"
                      alt=""
                      style={{ height: 20, width: 20 }}
                      onClick={() => {
                        setDeleteModalVisibility(true);
                        getSessionId(session?._id);
                      }}
                    />
                  </div>
                </div>
              );
            })}

            {filteredSessions.length === 0 && (
              <div style={{ textAlign: 'center' }}>
                <span>{t('texts.noSessionsFound')}</span>
              </div>
            )}
            <RenderIf isTrue={loadingNextPage}>
              <div style={{ textAlign: 'center' }}>
                <CircularProgress
                  size={23}
                  color="primary"
                  sx={{ position: 'static' }}
                />
              </div>
            </RenderIf>
          </div>

          {/* session upload image */}
          <BootstrapDialog
            onClose={profileClose}
            aria-labelledby="customized-dialog-title"
            open={openProfile}
          >
            <div className="popup-add-client-info popup-new-exercise-info">
              <div className="popup-add-client-content-info popup-create-programm-content-info">
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={profileClose}
                ></BootstrapDialogTitle>
                <div className="empty-space-20"></div>
                <form
                  className="popup-add-client-form-info"
                  onSubmit={SessionUploadImage}
                >
                  <table align="center" cellPadding="10" width="100%">
                    <tbody>
                      <tr>
                        <td className="font-size-22">
                          {t('texts.sessionImage')}
                        </td>
                        <td>
                          <input
                            type="file"
                            name="program-name"
                            className="form-control"
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="empty-space-30"></div>
                  <div className="mx-auto d-block">
                    <div className="btn">
                      <button className="margin-top-20px">
                        {t('buttons.save')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </BootstrapDialog>
          {/* end */}
        </div>
      </RenderIf>

      <MuiModal
        open={deleteModalVisibility}
        onClose={() => setDeleteModalVisibility(false)}
        onSubmit={DeleteSession}
        title={t('modals.titles.deleteSession')}
        description={t('modals.phrases.areYouSureDeleteSession')}
        submitBtnText={t('buttons.confirm')}
        closeBtnText={t('buttons.cancel')}
      />
    </React.Fragment>
  );
};

export default Session;
