import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { genrateToken } from '../../../firebase/token';
import moment from 'moment';
import { login } from '../../../Service/Api';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../../App';
import { useTranslation } from 'react-i18next';

const useLoginPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth } = React.useContext(AuthContext);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: '', password: '' } });

  const onSubmit = handleSubmit(async (data, event) => {
  
    try {
      const tkn = await genrateToken();

      let body = {
        email: data.email.toLowerCase(),
        password: data.password,
        deviceToken: tkn,
      };

      setIsLoading(true);
      const resdata = await login(body);

      if ((resdata, resdata.statusCode === 200)) {
        toast.success(t('success.login'));
        setAuth(resdata.data);

        localStorage.setItem('auth', JSON.stringify(resdata.data));
        localStorage.setItem('token', resdata.data.token);
        localStorage.setItem('islogin', true);

        const trialExpired = moment(new Date()).isAfter(resdata.trialPeriod);
        const hasSubscription = resdata.data?.subscriptionStatus === 'ACTIVE';

        if (trialExpired && !hasSubscription) {
          navigate('/plans');
        } else {
          navigate('/home');
        }
      } else {
        throw resdata;
      }
    } catch (err) {
      toast.error(err.error || t('errors.somethingWentWrong'));
    } finally {
      setIsLoading(false);
    }
  });

  return {
    register,
    onSubmit,
    isLoading,
    errors,
  };
};

export default useLoginPage;
