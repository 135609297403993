export const GET_MONTHLY_PLAN = 'GET_MONTHLY_PLAN';
export const MONTHLY_PLAN_REQUEST = 'MONTHLY_PLAN_REQUEST';
export const GET_MONTHLY_PLAN_FAILED = 'GET_MONTHLY_PLAN_FAILED';
////////////////////////////////
//*
export const YEARL_PLAN_REQUEST = 'YEARL_PLAN_REQUEST';
export const GET_YEARLY_PLAN = 'GET_YEARLY_PLAN';
export const FAILED_YEARLY_PLAN = 'FAILED_YEARLY_PLAN';

//users
export const HIT_USER_REQUEST = 'HIT_USER_REQUEST';
export const GET_USERS_DATA = 'GET_USERS_DATA';
export const FAILED_USER_DATA = 'FAILED_USER_DATA';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const GET_USERS_PAGINATION = 'GET_USERS_PAGINATION';

//**clients */
export const HIT_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENTS_DATA = 'GET_CLIENTS_DATA';
export const GET_CLIENTS_NEXT_PAGE = 'GET_CLIENTS_NEXT_PAGE';
export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const GET_CLIENTS_BY_IDS = 'GET_CLIENTS_BY_IDS';
export const FAILED_CLIENT_DATA = 'FAILED_CLIENT_DATA';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT_STATUS = 'UPDATE CLIENT_STATUS';
export const SEND_CLIENT_JOIN_REQUEST = 'UPDATE SEND_CLIENT_JOIN_REQUEST';
export const ASSIGN_COACH = 'ASSIGN_COACH';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST';
// *
export const HIT_CLIENT_SESSION_REQUEST = 'HIT_CLIENT_SESSION_REQUEST';
export const SET_CLIENT_SESSION = 'SET_CLIENT_SESSION';
export const FAILED_CLIENT_SESSION = 'FAILED_CLIENT_SESSION';
export const STOP_LOADING = 'STOP_LOADING';
export const EMPTY_DATA = 'EMPTY_DATA';
// ~ pure contants
//*  chats
export const CHAT_REQUEST_HIT = 'CHAT_REQUEST_HIT';
export const LOAD_CHAT_HISTORY = 'LOAD_CHAT_HISTORY';
export const FAILED_CHAT_REQUEST = 'FAILED_CHAT_REQUEST';
export const INSERT_CHAT_MESSAGE = 'INSERT_CHAT_MESSAGE';
export const EMPTY_CHAT = 'EMPTY_CHAT';
//* notifications
export const HIT_NOTIFICATION_REQUEST = 'HIT_NOTIFICATION_REQUEST';
export const SET_NOTIFICATION_REQUEST = 'SET_NOTIFICATION_REQUEST';
export const FAILED_NOTFICATION_REQUEST = 'FAILED_NOTFICATION_REQUEST';
export const NEW_NOTIFICATION_REQUEST = 'NEW_NOTIFICATION_REQUEST';
export const CHECKED_NOTIFICATION_REQUEST = 'CHECK_NOTIFICATION_REQUEST';

//* Programs
export const HIT_PROGRAM_REQUEST = 'HIT_PROGRAM_REQUEST';
export const SET_PROGRAM_DATA = 'SET_PROGRAM_DATA';
export const FAILED_PROGRAM_REQUEST = 'FAILED_PROGRAM_REQUEST';
export const UPDATE_PROGRAM_REQUEST = 'UPDATE_PROGRAM_REQUEST';
export const HIT_UPDATE_REQUEST = 'HIT_UPDATE_REQUEST';

//** Exercise
export const HIT_EXERCISES_REQUET = 'HIT_EXERCISES_REQUET';
export const SET_EXERCISES_REQUET = 'SET_EXERCISES_REQUET';
export const FAILED_EXERCISES_REQUET = 'FAILED_EXERCISES_REQUET';

//** session */
//*
export const HIT_SESSION_REQUEST = 'HIT_SESSION_REQUEST';
export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const FAILED_SESSION_DATA = 'FAILED_SESSION_DATA';
export const SET_EMPTY_STATE = 'SET_EMPTY_STATE';
export const SET_ORDER_OF_EXERCISES = 'SET_ORDER_OF_EXERCISES';
export const RESET_DATA = 'RESET_DATA';

//**assignedclientsessions */

export const HIT_ASSIGNCLIENTSESSIONS_REQUEST =
  'GET_ASSIGNCLIENTSESSIONS_REQUEST';
export const GET_ASSIGNCLIENTSESSIONS_DATA = 'GET_ASSIGNCLIENTSESSIONS_DATA';
export const FAILED_ASSIGNCLIENTSESSIONS_DATA =
  'FAILED_ASSIGNCLIENTSESSIONS_DATA';
export const UPDATE_ASSIGNCLIENTSESSIONS_STATUS =
  'UPDATE_ASSIGNCLIENTSESSIONS_STATUS';
export const SET_SIMPLE_SESSION_DATA = 'SET_SIMPLE_SESSION_DATA';
export const FAILED_SIMPLE_SESSION_DATA_REQUEST =
  'FAILED_SIMPLE_SESSION_DATA_REQUEST';
export const RESET_SESSION_DATA = 'RESET_SESSION_DATA';
export const INSERT_EXERCISES_IN_CLIENT_SESSION = 'INSERT_EXERCISES_REQUEST';
export const SET_ALL_CLIENT_SESSION = 'SET_ALL_CLIENT_SESSION';
export const FAILED_ALL_CLIENT_SESSION_REQUEST =
  'FAILED_ALL_CLIENT_SESSION_REQUEST';
export const UPDATE_ASSIGNED_SESSION = 'UPDATE_ASSIGNED_SESSION';
//*team Assigned session */
export const HIT_ASSIGNTEAMSESSIONS_REQUEST = 'HIT_ASSIGNTEAMSESSIONS_REQUEST';
export const SET_ASSIGNED_TEAM_SESSION = 'SET_ASSIGNED_TEAM_SESSION';
export const FAIL_ASSIGNED_TEAM_SESSION_REQUEST =
  'FAIL_ASSIGNED_TEAM_SESSION_REQUEST';
export const SET_ALL_ASSIGNED_SESSION = 'SET_ALL_ASSIGNED_SESSION';
export const RESET_TEAM_SESSION_ACTION = 'RESET_DATA_ACTION';
export const SET_TEAM_SIMPLE_SESSION = 'SET_TEAM_SIMPLE_SESSION';
export const UPDATE_TEAM_SESSION_DATE = 'UPDATE_TEAM_SESSION_DATE';
//** Tool  */
export const HIT_TOOL_REQUEST = 'HIT_TOOL_REQUEST';
export const SET_TOOL_DATA_REQUEST = 'SET_TOOL_DATA_REQUEST';
export const FAILED_TOOL_DATA_REQUEST = 'FAILED_TOOL_DATA_REQUEST';

//**Graph */

export const HIT_GRAPH_REQUEST = 'HIT_GRAPH_REQUEST';
export const SET_GRAPH_DATA_REQUEST = 'SET_GRAPH_DATA_REQUEST';
export const FAILED_GRAPH_DATA_REQUEST = 'FAILED_GRAPH_DATA_REQUEST';

//* session exercises
export const SET_SESSION_EXERCISES = 'SET_SESSION_EXERCISES';

//* teams */;
export const HIT_TEAMS_REQUEST = 'HIT_TEAMS_REQUEST';
export const GET_TEAMS_DATA = 'GET_TEAMS_DATA';
export const GET_TEAM_REQUEST = 'GET_TEAM_REQUEST';
export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST';
export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST';
export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const FAILED_TEAM_REQUEST = 'FAILED_TEAM_REQUEST';

//* session  feed

export const HIT_SESSION_FEED_REQUEST = 'HIT_SESSION_FEED_REQUEST';
export const SET_SESSION_FEED_DATA = 'SET_SESSION_FEED_DATA';
export const FAILED_SESSION_FEED_REQUEST = 'FAILED_SESSION_FEED_REQUEST';
export const RESET_SESSION_FEED = 'RESET_SESSION_FEED';
export const FILTER_SESSION_FEED_REQUEST = 'FILTER_SESSION_FEED_REQUEST';
export const SEARCH_SESSION_FEED_REQUEST = 'SEARCH_SESSION_FEED_REQUEST';
export const FAILED_SEARCH_SESSION_FEED = 'FAILED_SEARCH_SESSION_FEED';

//* payment INFO

export const HIT_PAYMENT_INFO_REQUEST = 'HIT_PAYMENT_INFO_REQUEST';
export const SET_PAYMENT_INFO_REQUEST = 'SET_PAYMENT_INFO_REQUEST';
export const FAILED_PAYMENT_INFO_REQUEST = 'FAILED_PAYMENT_INFO_REQUEST';

/*
 *bank cards
 */
export const HIT_CARD__REQUEST = 'HIT_CARD_INFO_REQUEST';
export const SET_CARD_REQUEST = 'SET_CARD_REQUEST';
export const FAILED_CARD_REQUEST = 'FAILED_CARD_REQUEST';
export const UPDATE_EXPIRY_REQUEST = 'UPDATE_EXPIRY_REQUEST';
export const SET_CARD_DEFAULT = 'SET_CARD_DEFAULT';
