import { Box, Stack } from '@mui/material';
import { TrainerProfileNavMenu } from '../trainer-profile/TrainerProfile';
import { useContext } from 'react';
import { AuthContext } from '../../App';

const VideoTutorial = () => {
  const { auth } = useContext(AuthContext);
  return (
    <Stack spacing={2}>
      <TrainerProfileNavMenu
        page="videotutorial"
        subscriptionStatus={auth.subscriptionStatus}
      />
      <Box className="boxes" sx={{ width: '100%' }}>
        <iframe
          style={{ width: '100%', maxWidth: 880 }}
          title="tutorial-video"
          height="500"
          src="https://www.youtube.com/embed/DA-uKm7btgA"
          frameborder="0"
          allowfullscreen
        />
      </Box>
    </Stack>
  );
};

export default VideoTutorial;
