import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../../App';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  getUsers,
  editUser,
  updateUserStatus,
} from '../../../redux/actions/Users';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useUsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  const usersData = useSelector((state) => state.users);

  const [page, setPage] = useState(0);

  const [userIdForStatusModal, setUserIdForStatusModal] = useState('');
  const [userIdForIsAdminModal, setUserIdForIsAdminModal] = useState('');
  const selectedUser = usersData.data.find(
    (user) =>
      user._id === userIdForStatusModal || user._id === userIdForIsAdminModal,
  );

  const orderByDirectionDict = { Name: 1, updatedOn: -1, frequent: -1 };
  const filterByRoleOptions = [
    { label: t('texts.any'), value: 'any' },
    { label: t('texts.coach'), value: 'coach' },
    { label: t('texts.client'), value: 'client' },
  ];
  const orderByOptions = [
    { label: t('texts.name'), value: 'Name' },
    { label: t('texts.recentlyUsed'), value: 'updatedOn' },
    { label: t('texts.frequentlyUsed'), value: 'frequent' },
  ];
  const userStatusOptions = [
    { value: 'Active', label: t('texts.active') },
    { value: 'Pending', label: t('texts.pending') },
    { value: 'Deactive', label: t('texts.deactivated') },
  ];

  const {
    control: controlOptions,
    register: registerOptions,
    watch: watchOptions,
  } = useForm({
    defaultValues: {
      filterByName: '',
      filterByStatus: userStatusOptions[0].value,
      filterByRole: 'any',
      orderBy: orderByOptions[0].value,
    },
  });

  const [filterByRole, filterByName, filterByStatus, orderBy] = watchOptions([
    'filterByRole',
    'filterByName',
    'filterByStatus',
    'orderBy',
  ]);

  const debouncedSearch = useDebounce(filterByName, 500);

  const fetchUsersWithOptions = (historyFetch) => {
    if (usersData.loading) return;

    const filters = {
      ...{ status: filterByStatus },
      ...(filterByName ? { Name: filterByName } : {}),
      ...(filterByRole !== 'any'
        ? { coach: { $exists: filterByRole === 'client' ? true : false } }
        : {}),
    };

    dispatch(
      getUsers(
        filters,
        orderBy ? { [orderBy]: orderByDirectionDict[orderBy] } : undefined,
        usersData.page + (historyFetch ? 1 : 0),
        historyFetch,
      ),
    );
  };

  const onChangeUserStatus = () => {
    dispatch(updateUserStatus([userIdForStatusModal], filterByStatus));
    setUserIdForStatusModal('');
  };

  const onChangeUserIsAdmin = () => {
    const user = usersData.data.find(
      (user) => user._id === userIdForIsAdminModal,
    );
    dispatch(
      editUser(
        userIdForIsAdminModal,
        {
          isAdmin: !user.isAdmin,
        },
        () => fetchUsersWithOptions(),
      ),
    );
    setUserIdForIsAdminModal('');
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;

    if (bottom && usersData.page < usersData.lastPage) {
      fetchUsersWithOptions(true);
    }
  };

  useEffect(() => {
    fetchUsersWithOptions();
  }, [debouncedSearch]);

  useEffect(() => {
    if (page > 0 && page < usersData.lastPage) {
      fetchUsersWithOptions(true);
    }
  }, [page]);

  useEffect(() => {
    setPage(0);
    fetchUsersWithOptions();
  }, [filterByStatus, filterByRole, orderBy]);

  useEffect(() => {
    if (!auth.isAdmin) navigate('/client');
  }, []);

  return {
    onChangeUserStatus,
    setUserIdForStatusModal,
    onChangeUserIsAdmin,
    setUserIdForIsAdminModal,
    registerOptions,
    handleScroll,
    userIdForIsAdminModal,
    userIdForStatusModal,
    controlOptions,
    userStatusOptions,
    orderByOptions,
    filterByRoleOptions,
    loading: usersData.loading,
    usersData: {
      ...usersData,
      data: usersData.data.filter((user) => user._id !== auth._id),
    },
    selectedUser,
  };
};

export default useUsersList;
