import { getDefaultMetadata } from '../../utils/constants';

export default function useExerciseSetExpanded({
  indexI,
  indexJ,
  exercise,
  handleSetsUpdate,
}) {
  const metrics = {
    reps: 'Reps',
    weight: 'Weight',
    calories: 'Calories',
    time: 'Time',
    distance: 'Distance',
    height: 'Height',
    rpe: 'RPE (1-10)',
  };

  const initialMetricOptions = [
    metrics.reps,
    metrics.calories,
    metrics.time,
    metrics.distance,
  ];

  const metricsCombinations = {
    reps: [metrics.weight, metrics.height, metrics.rpe, metrics.time],
    calories: [metrics.time, metrics.rpe],
    distance: [metrics.time, metrics.rpe],
    time: [metrics.distance, metrics.rpe, metrics.reps, metrics.calories],
  };

  const metricsUnits = {
    weight: [
      { label: 'Kgs', value: 'kgs' },
      { label: 'Lbs', value: 'lbs' },
    ],
    distance: [
      { label: 'Meters', value: 'meters' },
      { label: 'Kilometers', value: 'km' },
      { label: 'Inches', value: 'inches' },
      { label: 'Miles', value: 'miles' },
    ],
    height: [
      { label: 'Centimeters', value: 'cm' },
      { label: 'Inches', value: 'inches' },
    ],
  };

  const firstMetricType =
    exercise?.metadata?.[0]?.firstMetric?.type || Object.keys(metrics)?.[0];
  const secondMetricType =
    exercise?.metadata?.[0]?.secondMetric?.type ||
    Object.keys(metrics).find((metricKey) => {
      if (metrics[metricKey] === metricsCombinations[firstMetricType][0]) {
        return metricKey;
      }
    });

  const firstMetricUnit =
    exercise?.metadata?.[0]?.firstMetric?.unit ||
    metricsUnits[firstMetricType]?.[0]?.value;
  const secondMetricUnit =
    exercise?.metadata?.[0]?.secondMetric?.unit ||
    metricsUnits[secondMetricType]?.[0]?.value;

  const handleFirstMetricChange = (e) => {
    let newExerciseData = exercise.metadata;

    for (let i = 0; i < newExerciseData.length; i++) {
      const type = e.target.value;
      const secondMetric = {
        type: Object.keys(metrics).find(
          (key) => metrics[key] === metricsCombinations[type][0],
        ),
        value: '',
      };
      if (secondMetric.type === 'weight') {
        secondMetric.unit = JSON.parse(localStorage.getItem('auth')).weightUnit;
      } else if (secondMetric.type === 'height') {
        secondMetric.unit = JSON.parse(localStorage.getItem('auth')).heightUnit;
      }
      newExerciseData[i] = {
        ...newExerciseData[i],
        firstMetric: { type, value: '' },
        secondMetric,
      };
    }
    handleSetsUpdate(newExerciseData, indexI, indexJ);
  };

  const handleFirstMetricUnitChange = (e) => {
    const newExerciseData = exercise.metadata.map((data) => ({
      ...data,
      firstMetric: {
        ...data.firstMetric,
        unit: e.target.value,
      },
    }));
    handleSetsUpdate(newExerciseData, indexI, indexJ);
  };

  const handleSecondMetricChange = (e) => {
    let newExerciseData = exercise.metadata;

    const { heightUnit, weightUnit } = JSON.parse(localStorage.getItem('auth'));
    for (let i = 0; i < newExerciseData.length; i++) {
      if (e.target.value === 'weight') {
        newExerciseData[i] = {
          ...newExerciseData[i],
          secondMetric: {
            unit: weightUnit,
            type: e.target.value,
            value: '',
          },
        };
      } else if (e.target.value === 'height') {
        const unit = heightUnit === 'Feet & Inches' ? 'Inches' : 'Centimeters';
        newExerciseData[i] = {
          ...newExerciseData[i],
          secondMetric: {
            unit,
            type: e.target.value,
            value: '',
          },
        };
      } else {
        newExerciseData[i] = {
          ...newExerciseData[i],
          secondMetric: {
            type: e.target.value,
            value: '',
          },
        };
      }
    }
    handleSetsUpdate(newExerciseData, indexI, indexJ);
  };

  const handleSecondMetricUnitChange = (e) => {
    const newExerciseData = exercise.metadata.map((data) => ({
      ...data,
      secondMetric: {
        ...data.secondMetric,
        unit: e.target.value,
      },
    }));
    handleSetsUpdate(newExerciseData, indexI, indexJ);
  };

  const handleInputsUpdate = (newValues, setIndex) => {
    let newExerciseData = exercise.metadata;
    newExerciseData[setIndex] = newValues;

    handleSetsUpdate(newExerciseData, indexI, indexJ);
  };

  const handleAddNewSet = () => {
    const newSet = exercise.metadata.at(-1) || {
      ...getDefaultMetadata()[0],
      firstMetric: { type: firstMetricType },
      secondMetric: { type: secondMetricType },
    };
    handleSetsUpdate([...exercise.metadata, newSet], indexI, indexJ);
  };

  const handleRemoveSet = (index) => {
    handleSetsUpdate(
      exercise.metadata.filter((_, i) => i !== index),
      indexI,
      indexJ,
    );
  };

  return {
    initialMetricOptions,
    metricsCombinations,
    metrics,
    metricsUnits,
    firstMetricUnit,
    secondMetricUnit,
    firstMetricType,
    secondMetricType,
    handleRemoveSet,
    handleAddNewSet,
    handleInputsUpdate,
    handleFirstMetricChange,
    handleFirstMetricUnitChange,
    handleSecondMetricChange,
    handleSecondMetricUnitChange,
  };
}
