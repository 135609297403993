import {
  HIT_TEAMS_REQUEST,
  GET_TEAMS_DATA,
  FAILED_TEAM_REQUEST,
  CREATE_TEAM_REQUEST,
  UPDATE_TEAM_REQUEST,
  GET_TEAM_REQUEST,
  DELETE_TEAM_REQUEST,
} from '../constants';
import {
  getFilteredTeams,
  createTeam,
  uploadTeamProfilePhoto,
  updateTeamById,
  getTeamById,
  deleteTeamById,
} from '../../Service/Api';
import { toast } from 'react-toastify';
import i18n from '../../i18n.js';

export const setTeamEventData = (data) => {
  return {
    type: 'SET_TEAM_DATA',
    payload: {
      data: data,
    },
  };
};

export const getTeams = (filterBy, orderBy) => async (dispatch) => {
  dispatch({ type: HIT_TEAMS_REQUEST });

  try {
    const token = localStorage.getItem('token');
    const response = await getFilteredTeams(
      token,
      filterBy,
      orderBy,
    );
    if (response && response.statusCode === 200) {
      dispatch({
        type: GET_TEAMS_DATA,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_TEAM_REQUEST,
      payload: {
        error: err?.message || err?.error || i18n.t('errors.somethingWrong'),
      },
    });
  }
};

export const getTeam = (teamId) => async (dispatch) => {
  dispatch({ type: HIT_TEAMS_REQUEST });

  try {
    const token = localStorage.getItem('token');
    const response = await getTeamById(token, teamId);
    if (response && response.statusCode === 200) {
      dispatch({
        type: GET_TEAM_REQUEST,
        payload: {
          data: response.data,
        },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_TEAM_REQUEST,
      payload: {
        error: err?.message || err?.error || i18n.t('errors.somethingWrong'),
      },
    });
  }
};

export const deleteTeam = (teamId, onSuccess) => async (dispatch) => {
  dispatch({ type: HIT_TEAMS_REQUEST });

  try {
    const token = localStorage.getItem('token');
    const response = await deleteTeamById(token, teamId);

    if (response && response.statusCode === 200) {
      toast.success(i18n.t('success.teamDeleted'));
      onSuccess();

      dispatch({
        type: DELETE_TEAM_REQUEST,
        payload: { teamId },
      });
    } else {
      throw response;
    }
  } catch (err) {
    dispatch({
      type: FAILED_TEAM_REQUEST,
      payload: {
        error: err?.message || err?.error || i18n.t('errors.somethingWrong'),
      },
    });
  }
};

export const createNewTeam =
  ({ avatar, members, ...body }, onSuccess) =>
  async (dispatch) => {
    dispatch({ type: HIT_TEAMS_REQUEST });

    try {
      const token = localStorage.getItem('token');
      let response = await createTeam(token, { member: members, ...body });

      if (response && response.statusCode === 200) {
        if (avatar.length) {
          const teamPhotoForm = new FormData();
          teamPhotoForm.append('image', avatar[0]);
          response = await uploadTeamProfilePhoto(
            token,
            teamPhotoForm,
            response.data._id,
          );
        }

        toast.success(i18n.t('success.teamCreated'));
        onSuccess();
        dispatch({
          type: CREATE_TEAM_REQUEST,
          payload: { data: response.data },
        });
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(i18n.t('errors.somethingWrong'));
      dispatch({
        type: FAILED_TEAM_REQUEST,
        payload: {
          error: err?.message || err?.error || i18n.t('errors.somethingWrong'),
        },
      });
    }
  };

export const updateTeam =
  (teamId, { avatar, members, ...body }, onSuccess) =>
  async (dispatch) => {
    dispatch({ type: HIT_TEAMS_REQUEST });

    try {
      const token = localStorage.getItem('token');
      let response = await updateTeamById(token, teamId, {
        member: members,
        ...body,
      });

      if (response && response.statusCode === 200) {
        toast.success(i18n.t('success.teamUpdated'));
        onSuccess();
        dispatch({
          type: UPDATE_TEAM_REQUEST,
          payload: { data: response.data },
        });
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(i18n.t('errors.somethingWrong'));
      dispatch({
        type: FAILED_TEAM_REQUEST,
        payload: {
          error: err?.message || err?.error || i18n.t('errors.somethingWrong'),
        },
      });
    }
  };

export const updateTeamPhoto =
  (body, teamId, onSuccess) => async (dispatch) => {
    dispatch({ type: HIT_TEAMS_REQUEST });

    try {
      const token = localStorage.getItem('token');
      const response = await uploadTeamProfilePhoto(token, body, teamId);

      if (response && response.statusCode === 200) {
        toast.success(i18n.t('success.teamPhotoUpdated'));
        onSuccess();
        dispatch({
          type: UPDATE_TEAM_REQUEST,
          payload: {
            data: response.data,
          },
        });
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(i18n.t('errors.somethingWrong'));
      dispatch({
        type: FAILED_TEAM_REQUEST,
        payload: {
          error: err?.message || err?.error || i18n.t('errors.somethingWrong'),
        },
      });
    }
  };
