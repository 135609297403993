import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormSelectInput from '../../components/shared/FormSelectInput';
import MuiButton from '../../components/shared/MuiButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

const CalendarToolbar = ({
  label,
  onNavigate,
  teamId,
  clientId,
  selectOptions,
}) => {
  const location = useLocation()
  const navigate = useNavigate() 

  const { control } = useForm({ defaultValues: { teamId, clientId } });
  const { t } = useTranslation();

  const goToBack = () => {
    onNavigate('PREV');
    
  };
  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToToday = () => {
    onNavigate('TODAY');
  };

  const hadleSelectClient = (e) => {
    window.location.assign(
      `/${teamId ? 'team' : 'client'}/calendars/${e.target.value}`,
    );
  };

  const abbreviatedLabel = useMemo( () => {
    const [month, year ] = label.split(' ')
    return `${month.slice(0,3).replace('Sep', 'Sept')}. ${year}`
  }, [label])

  useEffect(() => {
    navigate({
        pathname: location.pathname,
        search: createSearchParams({ label }).toString()
    });
  }, [label])

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <FormSelectInput
        name={teamId ? 'teamId' : 'clientId'}
        control={control}
        label={teamId ? t('texts.teams') : t('texts.clients')}
        selectOptions={selectOptions?.map((data) => ({
          label: (teamId ? data.TeamName : data.Name) || '-',
          value: data._id,
        }))}
        onChange={hadleSelectClient}
        sx={{ minWidth: 350 }}
      />

      <div className="calendar-top-bar-info">
        <div className="calendar-top-grid">
          <div className="left-arrow-btn">
            <span onClick={goToBack}>
              <i className="fa fa-angle-left"></i>
            </span>
          </div>
          <div  style={{ minWidth: 150, marginTop: 10, marginRight: 10, textAlign: 'center' }}>
            <h3>
              {abbreviatedLabel}
            </h3>
          </div>
          <div className="right-arrow-btn">
            <span onClick={goToNext}>
              <i className="fa fa-angle-right"></i>
            </span>
          </div>
        </div>

        <MuiButton onClick={goToToday}>{t('buttons.goToToday')}</MuiButton>
      </div>
    </Stack>
  );
};

export default CalendarToolbar;
