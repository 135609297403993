import { DoneRounded } from '@mui/icons-material';
import MuiModal from '../../components/shared/MuiModal';
import FormInput from '../../components/shared/FormInput';
import { handleTimeFormat } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { useState } from 'react';
import FormSelectInput from '../../components/shared/FormSelectInput';

export const SimulateWorkoutModal = ({
  opened,
  onClose,
  membersOptions = [],
  handleWorkoutSimulation,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const defaultFormValues = {
    teamMemberId: null,
    totalSimulationTime: '',
    simulationDate: null,
  };

  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({ defaultValues: defaultFormValues });

  const totalSimulationTime = watch('totalSimulationTime');
  const simulationDate = watch('simulationDate');
  const teamMemberId = watch('teamMemberId');

  const setTotalSimulationTime = (value) =>
    setValue('totalSimulationTime', value);

  const onSubmit = handleSubmit(async () => {
    try {
      setLoading(true);
      if (!totalSimulationTime || totalSimulationTime === '00:00') {
        setTotalSimulationTime('00:00');
      } else {
        const success = await handleWorkoutSimulation(
          totalSimulationTime,
          simulationDate,
          teamMemberId,
        );
        if (success) {
          reset();
          onClose();
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });

  return (
    <MuiModal
      open={opened}
      submitBtnIcon={<DoneRounded />}
      onClose={onClose}
      onSubmit={onSubmit}
      closeBtnText={t('buttons.cancel')}
      submitBtnText={t('buttons.simulate')}
      containerSx={{ pt: 2 }}
      isLoading={loading}
    >
      <Stack gap={2}>
        <FormInput
          name="simulationDate"
          label={t('texts.simulationDate')}
          register={register}
          type="date"
          sx={{ fontSize: 20 }}
          error={errors.simulationDate}
          value={simulationDate}
          options={{ required: t('validations.dateRequired') }}
        />

        {!!membersOptions.length && (
          <FormSelectInput
            control={control}
            name="teamMemberId"
            selectOptions={membersOptions}
            label={t('texts.member')}
            options={{ required: t('validations.memberRequired') }}
          />
        )}

        <FormInput
          name="totalSimulationTime"
          label={t('texts.totalSimulationTime')}
          placeholder="00:00:00"
          sx={{ fontSize: 20 }}
          register={register}
          error={errors.totalSimulationTime}
          value={totalSimulationTime}
          options={{
            required: t('validations.simulationTimeRequired'),
            validate: (value) =>
              value === '00:00'
                ? t('validations.simulationTimeRequired')
                : true,
          }}
          onChange={({ target: { value } }) => {
            clearErrors('totalSimulationTime');
            setTotalSimulationTime(handleTimeFormat(value));
          }}
        />
      </Stack>
    </MuiModal>
  );
};
