import {
  AutoStories,
  BarChart,
  Groups,
  Insights,
  LibraryBooks,
  People,
  Person,
  RecentActors,
} from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { Trans, useTranslation } from 'react-i18next';
import Logo from '../shared/Logo';
import { AuthContext } from '../../App';
import Tag from '../shared/Tag';

export const SIDEBAR_WIDTH = '180px';

const SideBar = () => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="sidebar-componnet">
      <Stack
        alignItems="center"
        justifyContent="center"
        onClick={() => navigate('/')}
        sx={{
          cursor: 'pointer',
          width: '100%',
          boxShadow: '0px 2px rgb(0 0 0 / 25%)',
        }}
      >
        <img
          alt="logo"
          src="/assets/logo.png"
          style={{
            marginBottom: 7,
            padding: 15,
            marginTop: 0,
            height: '100%',
          }}
        />
      </Stack>

      <div className="links" style={{ paddingTop: 0 }}>
        <ul className="nav flex-column">
          <li className="nav-item" style={{ marginTop: 0 }}>
            <NavLink to="/client" activeclassname="is-active">
              {' '}
              <People /> {t('sidebar.clients')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/team" activeclassname="is-active">
              {' '}
              <Groups />
              {t('sidebar.teams')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/librarypage" activeclassname="is-active">
              {' '}
              <AutoStories />
              {t('sidebar.library')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/statistics" activeclassname="is-active">
              {' '}
              <Insights />
              {t('sidebar.statistics')}
            </NavLink>
          </li>
          {auth.isAdmin && (
            <li className="nav-item">
              <NavLink to="/admin/users" activeclassname="is-active">
                <RecentActors />
                <div style={{ position: 'relative' }}>
                  {t('sidebar.allUsers')}
                  <Typography
                    sx={{
                      position: 'absolute',
                      right: -35,
                      top: -12,
                      color: 'primary.main',
                      fontSize: 11,
                    }}
                  >
                    Admin
                  </Typography>
                </div>
              </NavLink>
            </li>
          )}
          {auth.isAdmin && (
            <li className="nav-item">
              <NavLink to="/teams" activeclassname="is-active">
                <RecentActors />
                <div style={{ position: 'relative' }}>
                  {t('sidebar.allTeams')}
                  <Typography
                    sx={{
                      position: 'absolute',
                      right: -35,
                      top: -12,
                      color: 'primary.main',
                      fontSize: 11,
                    }}
                  >
                    Admin
                  </Typography>
                </div>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
export default SideBar;
