import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './SessionsCalendar.css';
import { Stack, Typography } from '@mui/material';
import {
  ContentCopy,
  DeleteOutline,
  DragIndicatorOutlined,
} from '@mui/icons-material';
import CalendarToolbar from './CalendarToolbar';
import useSessionsCalendar from './useSessionsCalendar';
import ActionsModals from './ActionsModals';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const SessionsCalendar = () => {
  const {
    selectedDateSessions,
    setSelectedDateSessions,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    isCopyModalVisible,
    setIsCopyModalVisible,
    isMoveModalVisible,
    setIsMoveModalVisible,
    handleSelectedEvent,
    today,
    teamsData,
    clientsData,
    sessions,
    teamId,
    clientId,
    updateSessions,
    getDateWithTimezone,
  } = useSessionsCalendar();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams()

  const renderCalendarEvent = (e) => {
    const key = getDateWithTimezone(e.date);
    const sessionAssigned = sessions[key];
    const isToday = key === today;

    const hasSession = sessionAssigned?.length;
    let sessionTitle;

    const max_title_length = 30;
    if (sessionAssigned?.length === 1) {
      sessionTitle = sessionAssigned[0].title.slice(0, max_title_length);
      if (sessionTitle.length === max_title_length) sessionTitle += '...';
    } else {
      sessionTitle = `${sessionAssigned?.length} sessions scheduled`;
    }

    return (
      <Stack
        justifyContent="space-between"
        sx={{
          height: 1,
          width: 1,
          cursor: 'pointer',
          pl: 1.5,
          pr: 1,
          pt: 0.5,
          pb: 1,
          border: isToday ? 0.25 : 0,
          '&:hover': {
            border: 1,
            borderColor: hasSession
              ? 'rgba(75, 181, 67, 0.7)'
              : 'rgba(50, 180, 255, 0.6)',
          },
          borderColor: 'rgba(50, 180, 255, 0.6)',
        }}
        onClick={() => {
          handleSelectedEvent(e);
        }}
      >
        <Stack justifyContent="space-between" alignItems="flex-start">
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            sx={{ width: 1 }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: 1 }}
            >
              <Typography
                sx={{
                  color: hasSession ? 'success.main' : 'white',
                  fontWeight: hasSession ? '600' : '500',
                  fontSize: 18,
                  mb: 1,
                }}
              >
                {e.label}
              </Typography>

              {isToday && (
                <div>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      letterSpacing: 3,
                      fontSize: 14,
                    }}
                  >
                    TODAY
                  </Typography>
                </div>
              )}
            </Stack>

            {hasSession ? (
              <Typography
                sx={{
                  color: 'success.main',
                  textAlign: 'start',
                  fontSize: 14,
                  mt: -0.5,
                }}
              >
                {sessionTitle}
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: 'secondary.main',
                  textAlign: 'start',
                  fontSize: 14,
                }}
              >
                + {t('buttons.scheduleSessions')}
              </Typography>
            )}
          </Stack>
        </Stack>

        {hasSession && (
          <Stack
            direction="row"
            spacing={1.25}
            alignItems="start"
            mt={0.5}
            justifyContent="end"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDateSessions(sessionAssigned);
            }}
          >
            <ContentCopy
              sx={{
                width: 18,
                height: 18,
                mt: '1px',
                '&:hover': { color: 'success.main' },
              }}
              onClick={() => setIsCopyModalVisible(true)}
            />
            <DragIndicatorOutlined
              sx={{
                width: 20,
                height: 20,
                '&:hover': { color: 'success.main' },
              }}
              onClick={() => setIsMoveModalVisible(true)}
            />
            <DeleteOutline
              sx={{
                width: 20,
                height: 20,
                '&:hover': { color: 'success.main' },
              }}
              onClick={() => setIsDeleteModalVisible(true)}
            />
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <>
      {sessions && (clientsData?.length || teamsData?.length)
        ? Object.keys(sessions).length >= 0 && (
            <DnDCalendar
              defaultDate={searchParams.get('label')}
              localizer={localizer}
              components={{
                toolbar: (props) =>
                  CalendarToolbar({
                    ...props,
                    teamId,
                    clientId,
                    selectOptions: teamId ? teamsData : clientsData,
                  }),
                month: {
                  dateHeader: renderCalendarEvent,
                },
              }}
              resizable
            />
          )
        : null}
      {selectedDateSessions?.length && (
        <ActionsModals
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isCopyModalVisible={isCopyModalVisible}
          setIsCopyModalVisible={setIsCopyModalVisible}
          isMoveModalVisible={isMoveModalVisible}
          setIsMoveModalVisible={setIsMoveModalVisible}
          selectedDateSessions={selectedDateSessions}
          sessions={sessions || {}}
          updateSessions={updateSessions}
        />
      )}
    </>
  );
};

export default SessionsCalendar;
