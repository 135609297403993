import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import LoaderContext from '../../context/Loader';
import { useDebounce } from '../../hooks/useDebounce';
import { deleteTeam, getTeams } from '../../redux/actions/Teams';
import { getAllUsers } from '../../Service/Api';

const useTeamList = ({ showAll }) => {
  const dispatch = useDispatch();
  const loader = useContext(LoaderContext);
  const { auth } = useContext(AuthContext);

  const teamsData = useSelector((state) => state.teams);
  const [membersOptions, setMembersOptions] = useState([]);

  const [showTeamChat, setShowTeamChat] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);
  const [showSaveTeamModal, setShowSaveTeamModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const orderByDirectionDict = { TeamName: 1, updatedOn: -1, frequent: -1 };
  const orderByOptions = [
    { label: 'Name', value: 'TeamName' },
    { label: 'Recently used', value: 'updatedOn' },
    { label: 'Frequently used', value: 'frequent' },
  ];

  const {
    control: controlOptions,
    register: registerOptions,
    watch: watchOptions,
  } = useForm({
    defaultValues: {
      filterByName: '',
      orderBy: orderByOptions[0].value,
    },
  });

  const [filterByName, orderBy] = watchOptions(['filterByName', 'orderBy']);

  const debouncedSearch = useDebounce(filterByName, 500);

  useEffect(() => {
    fetchTeamsWithOptions();
  }, [debouncedSearch, showAll]);

  const fetchTeamsWithOptions = () => {
    dispatch(
      getTeams(
        {
          ...(auth.isAdmin && showAll ? {} : { trainerId: auth._id }),
          ...(filterByName ? { TeamName: filterByName } : {}),
        },
        orderBy ? { [orderBy]: orderByDirectionDict[orderBy] } : undefined,
      ),
    );
  };

  const onCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setSelectedTeam(null);
  };

  const onDeleteTeam = () => {
    dispatch(deleteTeam(selectedTeam._id, onCloseConfirmationModal));
  };

  const fetchMembers = async () => {
    const members = await getAllUsers(
      auth.token,
      auth.isAdmin ? {} : { coach: auth._id },
      null,
      0,
      1000,
    );

    setMembersOptions(
      members.data.map((c) => ({
        value: c._id,
        label: c.Name || c.email.split('@')[0],
      })),
    );
  };

  useEffect(() => {
    fetchTeamsWithOptions();
    fetchMembers();
  }, [orderBy]);

  useEffect(() => {
    loader.setLoading(teamsData.loading);
  }, [loader, teamsData.loading]);

  return {
    trainerId: auth._id,
    isLoading: teamsData?.loading,
    orderByOptions,
    onDeleteTeam,
    showTeamChat,
    setShowTeamChat,
    setShowConfirmationModal,
    showConfirmationModal,
    showEditPhotoModal,
    setShowEditPhotoModal,
    showSaveTeamModal,
    setShowSaveTeamModal,
    setSelectedTeam,
    selectedTeam,
    teamsData,
    membersOptions,
    controlOptions,
    registerOptions,
    fetchTeamsWithOptions,
  };
};

export default useTeamList;
