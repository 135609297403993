import React, { useState, useEffect, useContext } from 'react';
import './LibrarySession.css';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionAction } from '../../redux/actions/Sessions';
import {
  assignClientSessionApi,
  sessionCreate,
  simulateWorkout,
  updateSessionData,
  uploadSessionPhoto,
} from '../../Service/Api';
import SelectExercisesModal from '../../components/SelectExercisesModal/SelectExercisesModal';
import SessionExercisesList from '../../components/SessionExercisesList/SessionExercisesList';
import FormInput from '../../components/shared/FormInput';
import RenderIf from '../../customComponents/RenderIf';
import Loader from '../loader/Loader';
import { Stack } from '@mui/material';
import MuiButton from '../../components/shared/MuiButton';
import { AddOutlined, Save } from '@material-ui/icons';
import { deleteSession } from '../../Service/Api';
import { AuthContext } from '../../App';
import MuiModal from '../../components/shared/MuiModal';
import { useTranslation } from 'react-i18next';
import FormFileInput from '../../components/shared/FormFileInput';
import { DoneRounded } from '@mui/icons-material';
import { SimulateWorkoutModal } from '../ClientSessionAssign/SimulateWorkoutModal';
import { getClients } from '../../redux/actions/Clients';
import moment from 'moment';

const CalendarAddExr = () => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [sessionName, setSessionName] = useState('');
  const [sessionInstructions, setSessionInstructions] = useState('');
  const [currentExercises, setCurrentExercises] = useState([]);
  const [exercisesModalVisibility, setExercisesModalVisibility] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [simulateWorkoutOpened, setSimulateWorkoutOpened] = useState(false);

  const [photo, setPhoto] = useState('');

  const { sessionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionDataObj = useSelector((s) => s.sessionData);
  const clientsData = useSelector((state) => state.client);

  const clientsOptions = clientsData.data.map((client) => ({
    value: client._id,
    label: client.Name,
  }));

  useEffect(() => {
    if (sessionId) {
      dispatch(getSessionAction(sessionId));
    }
    fetchClients();
  }, []);

  useEffect(() => {
    if (!sessionId) return;
    if (sessionDataObj.loading === true) {
      setLoading(true);
    }
    if (sessionDataObj.loading === false) {
      setLoading(false);
      if (sessionDataObj?.data) {
        const { SessionName, exercises, Instructions } = sessionDataObj?.data;
        const exercisesFiltered = exercises.filter(
          (e) => typeof e === 'object',
        );
        setSessionName(SessionName);
        setSessionInstructions(Instructions);
        setCurrentExercises(exercisesFiltered);
      }
    }
  }, [sessionDataObj.loading]);

  const fetchClients = () => {
    const filters = { status: 'Active' };
    dispatch(
      getClients({
        coachId: auth._id,
        filterBy: filters,
        page: 0,
        pageSize: 1000,
      }),
    );
  };

  const handleAddExercisesToSession = (newExercises) => {
    const aux = [...currentExercises];
    for (let exercises of newExercises) {
      for (let innerExercise of exercises) {
        aux.push([innerExercise]);
      }
    }
    setCurrentExercises(aux);
    setExercisesModalVisibility(false);
  };

  const addSuperset = (i, j) => {
    const aux = [...currentExercises];
    if (j > 0) {
      const exercisesToAdd = aux[i + 1];
      for (let exercises of exercisesToAdd) {
        aux[i].push(exercises);
      }
      aux.splice(i + 1, 1);
    } else {
      if (aux[i + 1].length > 1) {
        const exerciseToAdd = { ...currentExercises[i][0] };
        aux[i + 1].push(exerciseToAdd);
        aux.splice(i, 1);
      } else {
        const exerciseToAdd = { ...currentExercises[i + 1][0] };
        aux[i].push(exerciseToAdd);
        aux.splice(i + 1, 1);
      }
    }
    setCurrentExercises(aux);
  };

  const removeSuperset = (i, j) => {
    const exerciseToRemove = { ...currentExercises[i][j + 1] };
    let aux = [...currentExercises];
    aux[i].splice(j + 1, 1);
    aux.push([exerciseToRemove]);
    setCurrentExercises([...aux]);
  };

  const sessionExists = () => !!sessionId;

  const isExercisesInvalid = () => {
    if (currentExercises.length === 0 || currentExercises[0].length === 0) {
      toast.error(t('validations.exercisesRequired'));
      return true;
    } else if (!sessionName || sessionName.trim().length === 0) {
      toast.error(t('validations.titleRequired'));
      return true;
    } else {
      const isMetricFieldsInvalid = currentExercises
        .reduce(
          (allExercises, exercises) => [...allExercises, ...exercises],
          [],
        )
        .reduce(
          (metadatas, exercise) => [...metadatas, ...exercise.metadata],
          [],
        )
        .find(
          (data) =>
            !data.firstMetric.value ||
            !data.secondMetric.value ||
            !data.tempo ||
            !data.rest,
        );

      if (isMetricFieldsInvalid) {
        toast.error(t('validations.allTableValuesRequired'));
        return true;
      }
    }
  };

  const update = async () => {
    if (isExercisesInvalid()) return;
    const exercisesMetadata = currentExercises
      .flat()
      .map(({ _id, metadata }) => ({ exerciseId: _id, metadata }));

    const formData = new FormData();
    formData.append('image', photo);

    const body = {
      SessionName: sessionName,
      Instructions: sessionInstructions,
      exercises: currentExercises,
      exercisesMetadata,
    };
    const response = await updateSessionData(body, auth.token, sessionId);

    if (response.statusCode === 200) {
      await uploadSessionPhoto(auth.token, formData, sessionDataObj?.data?._id);
      toast.success(t('success.sessionUpdated'));
    } else {
      toast.error(t('errors.somethingWentWrong'));
    }
  };

  const create = async () => {
    if (isExercisesInvalid()) return;

    const formData = new FormData();
    formData.append('image', photo);

    const { _id, token } = auth;
    const body = {
      SessionName: sessionName,
      Instructions: sessionInstructions,
      trainerId: _id,
      exercises: currentExercises,
    };

    try {
      const response = await sessionCreate(body, token);

      if (response.statusCode === 200) {
        toast.success(t('success.sessionCreated'));
        const { _id } = response.data;
        await uploadSessionPhoto(auth.token, formData, _id);

        navigate('/librarypage/session/' + _id);
      } else {
        throw new Error(response.error || t('errors.somethingWentWrong'));
      }
    } catch (err) {
      throw new Error(err.message || t('errors.somethingWentWrong'));
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteSession(sessionId, auth.token);
      if (response.statusCode === 200) {
        toast.success(t('success.sessionDeleted'));
        navigate('/librarypage/sessions');
      } else {
        throw new Error(t('errors.somethingWentWrong'));
      }
    } catch (err) {
      throw new Error(err.message || t('errors.somethingWentWrong'));
    }
  };

  const sessionSubmit = async () => {
    setLoading(true);
    try {
      if (!sessionExists()) {
        await create();
      } else {
        await update();
      }
    } catch (err) {
      console.log('err', err);
      toast.error(err.message || t('errors.somethingWentWrong'));
    }
    setLoading(false);
  };

  const handleWorkoutSimulation = async (
    totalTime,
    simulationDate,
    clientId,
  ) => {
    try {
      if (!totalTime) return !isExercisesInvalid();

      const assignedSessionRes = await assignClientSessionApi({
        title: sessionName,
        Instructions: sessionInstructions,
        trainerId: auth._id,
        exercises: currentExercises,
        userId: clientId,
        date: moment.utc(simulationDate).format('YYYY-MM-DD'),
      });

      const res = await simulateWorkout({
        workoutData: {
          assignedExercises: currentExercises.map((exercises) =>
            exercises.map((exercise) => ({
              exerciseId: exercise._id,
              inputs: exercise.metadata.map((workout) => ({
                ...workout,
                checked: true,
              })),
            })),
          ),
        },
        assignedSessionId: assignedSessionRes.data._id,
        clientId,
        trainerId: auth._id,
        totalTime,
        simulationDate,
      });

      if (res.isSuccess) {
        toast.success('Workout successfully simulated!');
        return true;
      } else {
        toast.error("Something wen't wrong. Please try again later.");
      }
    } catch (err) {
      throw new Error(err.message || t('errors.somethingWentWrong'));
    }
  };

  return (
    <div>
      <RenderIf isTrue={loading}>
        <Loader />
      </RenderIf>

      <SelectExercisesModal
        onClose={() => setExercisesModalVisibility(false)}
        isOpen={exercisesModalVisibility}
        addExercises={handleAddExercisesToSession}
      />
      <Stack direction="row" justifyContent="end" gap={2} mt={1} mb={2.5}>
        <MuiButton
          onClick={() => {
            if (handleWorkoutSimulation()) {
              setSimulateWorkoutOpened(true);
            }
          }}
          icon={<DoneRounded />}
        >
          {t('buttons.simulateWorkout')}
        </MuiButton>

        <div className="btn-top-cstm">
          <MuiButton
            onClick={() => setExercisesModalVisibility(true)}
            icon={<AddOutlined />}
          >
            {t('buttons.addExercises')}
          </MuiButton>
        </div>

        {sessionExists() ? (
          <div className="btn-top-cstm">
            <MuiButton onClick={() => sessionSubmit()} icon={<Save />}>
              {t('buttons.save')}
            </MuiButton>
          </div>
        ) : (
          <div className="btn-top-cstm">
            <MuiButton onClick={() => sessionSubmit()} icon={<Save />}>
              {t('buttons.create')}
            </MuiButton>
          </div>
        )}

        <img
          style={{ cursor: 'pointer' }}
          onClick={() => setDeleteModalVisibility(true)}
          src="/assets/bx_trash.svg"
          className="img-fluid"
          alt="Delete session"
          title="Delete session"
        />
      </Stack>

      <Stack direction="row" spacing={2} ml={2} alignItems="end">
        <FormFileInput
          name="profilePhoto"
          label="Photo"
          onInput={({ target: { files } }) => setPhoto(files[0])}
          options={{ required: 'Photo required' }}
          sx={{ width: 600, mb: 1.5 }}
        />
        <FormInput
          label={t('texts.sessionName')}
          onChange={(e) => setSessionName(e.target.value)}
          name="sessionName"
          placeholder={t('placeholders.enterSessionTitle')}
          value={sessionName}
          style={{ width: '20vw' }}
        />
        <FormInput
          label={t('texts.sessionInstructions')}
          onChange={(e) => setSessionInstructions(e.target.value)}
          name="sessionInstructions"
          placeholder={t('placeholders.enterSessionInstructions')}
          value={sessionInstructions}
          containerSx={{ width: '100%' }}
        />
      </Stack>

      <SessionExercisesList
        isLibrarySection
        addSuperset={addSuperset}
        removeSuperset={removeSuperset}
        exercises={currentExercises}
        updateExercisesList={(e) => setCurrentExercises(e)}
        isExpanded={true}
      />

      <MuiModal
        open={deleteModalVisibility}
        onClose={() => setDeleteModalVisibility(false)}
        onSubmit={handleDelete}
        title={t('modals.titles.deleteSession')}
        description={t('modals.phrases.areYouSureDeleteSession')}
        submitBtnText={t('buttons.confirm')}
        closeBtnText={t('buttons.cancel')}
      />

      <SimulateWorkoutModal
        membersOptions={clientsOptions}
        opened={simulateWorkoutOpened}
        handleWorkoutSimulation={handleWorkoutSimulation}
        onClose={() => setSimulateWorkoutOpened(false)}
      />
    </div>
  );
};

export default CalendarAddExr;
