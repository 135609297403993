import { InputLabel, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import FormInput from '../shared/FormInput';
import MuiModal from '../shared/MuiModal';
import useSaveExercise from './useSaveExercise';
import { useTranslation } from 'react-i18next';
import SelectInput from '../shared/SelectInput';
import { CheckCircle } from '@mui/icons-material';

const SaveExercise = ({ exercise, open, onClose, updateExercises }) => {
  const { t } = useTranslation();
  const { register, submit, enableLangugage, languages, errors, loading } =
    useSaveExercise({
      onClose,
      exercise,
      updateExercises,
      open,
    });

  return (
    <MuiModal
      title={
        exercise
          ? t('modals.titles.updateExercise')
          : t('modals.titles.createExercise')
      }
      open={open}
      onClose={onClose}
      onSubmit={submit}
      submitBtnText={exercise ? t('buttons.save') : t('buttons.create')}
      isLoading={loading}
    >
      <Stack spacing={2} py={2}>
        <Stack>
          <InputLabel shrink sx={{ color: 'white' }}>
            {t('texts.language')}
          </InputLabel>
          <Stack spacing={2} direction="row" mb={2}>
            <Stack
              spacing={0.5}
              direction="row"
              sx={{ cursor: 'pointer' }}
              onClick={() => enableLangugage('English')}
            >
              <CheckCircle
                sx={{
                  color: languages.includes('English')
                    ? 'success.main'
                    : 'disabled.main',
                }}
              />
              <Typography
                sx={{
                  color: languages.includes('English')
                    ? 'success.main'
                    : 'disabled.main',
                }}
              >
                English
              </Typography>
            </Stack>
            <Stack
              spacing={0.5}
              direction="row"
              sx={{ cursor: 'pointer' }}
              onClick={() => enableLangugage('Swedish')}
            >
              <CheckCircle
                sx={{
                  color: languages.includes('Swedish')
                    ? 'success.main'
                    : 'disabled.main',
                }}
              />
              <Typography
                sx={{
                  color: languages.includes('Swedish')
                    ? 'success.main'
                    : 'disabled.main',
                }}
              >
                Swedish
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <FormInput
          register={register}
          label="Youtube URL"
          name="YoutubeUrl"
          placeholder="Ex: https://www.youtube.com/watch?v=8"
        />

        {languages.includes('English') && (
          <FormInput
            register={register}
            label={t('texts.exerciseName')}
            name="ExerciseName"
            placeholder={t('placeholders.enterExerciseName')}
            error={errors.ExerciseName}
            options={
              languages.includes('English')
                ? {
                    required: t('validations.exerciseNameRequired'),
                  }
                : {}
            }
          />
        )}
        {languages.includes('Swedish') && (
          <FormInput
            register={register}
            label={`${t('texts.exerciseName')} (Swedish)`}
            name="ExerciseNameSE"
            placeholder={`${t('placeholders.enterExerciseName')} (Swedish)`}
            error={errors.ExerciseNameSE}
            options={
              languages.includes('Swedish')
                ? {
                    required: t('validations.exerciseNameRequired'),
                  }
                : {}
            }
          />
        )}

        {languages.includes('English') && (
          <div>
            <InputLabel shrink sx={{ color: 'white', mb: 0, ml: 0.5 }}>
              {t('texts.instructions')}
            </InputLabel>
            <textarea
              {...register('Instructions')}
              placeholder={t('placeholders.exerciseInstructions')}
              className="form-control"
              style={{
                backgroundColor: '#2E313F',
                color: 'white !important',
                borderColor: 'transparent',
                borderRadius: 10,
              }}
              rows="4"
              cols="60"
            />
          </div>
        )}
        {languages.includes('Swedish') && (
          <div>
            <InputLabel shrink sx={{ color: 'white', mb: 0, ml: 0.5 }}>
              {`${t('texts.instructions')} (Swedish)`}
            </InputLabel>
            <textarea
              {...register('InstructionsSE')}
              placeholder={`${t(
                'placeholders.exerciseInstructions',
              )} (Swedish)`}
              className="form-control"
              style={{
                backgroundColor: '#2E313F',
                color: 'white !important',
                borderColor: 'transparent',
                borderRadius: 10,
              }}
              rows="4"
              cols="60"
            />
          </div>
        )}
      </Stack>
    </MuiModal>
  );
};

export default SaveExercise;
