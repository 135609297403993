import {
  HIT_USER_REQUEST,
  GET_USERS_DATA,
  FAILED_USER_DATA,
  UPDATE_USER,
  UPDATE_USER_STATUS,
  GET_USERS_PAGINATION,
} from '../constants';

const initialValue = {
  selectedUser: null,
  data: [],
  error: '',
  page: 0,
  pageSize: 10,
  loading: false,
};

export const usersReducer = (state = initialValue, { type, payload }) => {
  switch (type) {
    case HIT_USER_REQUEST: {
      return { ...state, loading: true };
    }

    case GET_USERS_DATA: {
      return {
        ...state,
        data: payload.data,
        page: 0,
        lastPage: payload.lastPage,
        loading: false,
      };
    }

    case GET_USERS_PAGINATION: {
      return {
        ...state,
        data: [...state.data, ...payload.data],
        page: payload.page,
        lastPage: payload.lastPage,
        loading: false,
      };
    }

    case FAILED_USER_DATA: {
      return {
        ...state,
        loading: false,
        error: payload?.error,
      };
    }

    case UPDATE_USER: {
      return {
        ...state,
        data: state.data.map((user) =>
          user._id === payload.data._id ? payload.data : user,
        ),
        loading: false,
      };
    }

    case UPDATE_USER_STATUS: {
      const filtred = state.data.filter((obj) => {
        return !payload.filtredIds.includes(obj._id);
      });
      return {
        ...state,
        loading: false,
        data: [...filtred],
      };
    }

    default: {
      return { ...state };
    }
  }
};
