import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import MuiTable from '../../components/shared/MuiTable';
import useStatistics from './useStatistics';
import './Statistics.css';
import SelectInput from '../../components/shared/SelectInput';
import React, { useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Statistics = () => {
  const {
    data,
    tableData,
    shouldShowChart,
    isMetricsInvalid,
    tableHeaders,
    chartOptions,
    selectedClient,
    setSelectedClient,
    selectedExercise,
    setSelectedExercise,
    exercises,
    clients,
    endDate,
    startDate,
    setStartDate,
    setEndDate,
    page,
    setPage,
    pageSize,
    setPageSize,
    aggregation,
    setAggregation,
    dateAggregations,
    isMonthSegmentation,
    max1RMCalc,
  } = useStatistics();
  const { t } = useTranslation();

  const { weightUnit } = JSON.parse(localStorage.getItem('auth'));

  const Card = ({ sx, children, ...props }) => {
    return (
      <Stack
        sx={{
          bgcolor: 'background.main',
          borderRadius: 2.5,
          p: 3,
          pt: 6,
          pl: 1,
          ...sx,
        }}
        {...props}
      >
        {children}
      </Stack>
    );
  };

  const chart = useMemo(
    () => (
      <Card alignItems="flex-end" spacing={2} sx={max1RMCalc ? { pt: 4 } : {}}>
        {!isNaN(max1RMCalc?.value) && (
          <Stack direction="row" spacing={1} alignItems="center" mr={2}>
            <Box
              sx={{
                width: 18,
                height: 18,
                borderRadius: '50%',
                bgcolor: '#FEA41D',
              }}
            />
            <Typography sx={{ color: 'white', fontSize: 14 }}>
              Max 1RM Calc: {max1RMCalc?.value?.toFixed(2)} {weightUnit}
            </Typography>
            <Typography sx={{ color: '#42D114', fontSize: 14, pl: 1 }}>
              +
              {((max1RMCalc?.value * 100) / data[0].oneRMCalcMax - 100).toFixed(
                2,
              )}
              %
            </Typography>
            <Tooltip title="Highest improvement since the beginning of the selected period">
              <InfoOutlined sx={{ width: 15, height: 15 }} />
            </Tooltip>
          </Stack>
        )}
        <Box sx={{ width: '100%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            oneToOne={true}
          />
        </Box>
      </Card>
    ),
    [chartOptions],
  );

  return (
    <Stack spacing={2} sx={{ pb: 4 }}>
      <Typography variant="h4" sx={{ textAlign: 'start' }}>
        {t('texts.exerciseProgression')}
      </Typography>
      <Card sx={{ pt: 3, pl: 2 }} spacing={2.5}>
        <Stack spacing={4} direction="row">
          <SelectInput
            label={t('texts.aggregation')}
            labelInfo={t('tooltips.aggregation')}
            containerSx={{ width: '50%' }}
            value={aggregation}
            onChange={({ target: { value } }) => setAggregation(value)}
            selectOptions={dateAggregations?.map((aggregation) => ({
              value: aggregation,
              label: aggregation,
            }))}
            hideError
          />
          <SelectInput
            label={t('texts.client')}
            containerSx={{ width: '100%' }}
            value={selectedClient}
            onChange={({ target: { value } }) => setSelectedClient(value)}
            selectOptions={clients?.map((client) => ({
              value: client,
              label: client.Name || '-',
            }))}
            hideError
          />
          <SelectInput
            label={t('texts.exercise')}
            containerSx={{ width: '100%' }}
            value={selectedExercise}
            onChange={({ target: { value } }) => setSelectedExercise(value)}
            selectOptions={exercises?.map((exercise) => ({
              value: exercise,
              label: exercise.ExerciseName,
            }))}
            hideError
          />
        </Stack>

        <Stack
          direction="row"
          spacing={4}
          sx={{
            '& .MuiInputBase-root': {
              bgcolor: 'background.light',
              boxShadow: 3,
            },
            '& .MuiFormLabel-root': {
              color: 'white',
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={t('texts.fromDate')}
              value={startDate}
              sx={{ width: 1 }}
              onChange={(value) =>
                isMonthSegmentation
                  ? setStartDate(moment(value).startOf('month'))
                  : setStartDate(value)
              }
              maxDate={moment(endDate).subtract(1, 'day')}
              openTo={aggregation.toLocaleLowerCase()}
              views={['year', 'month', ...(isMonthSegmentation ? [] : ['day'])]}
              slotProps={
                isMonthSegmentation
                  ? {}
                  : {
                      textField: {
                        helperText: 'MM/DD/YYYY',
                      },
                    }
              }
            />
            <DatePicker
              label={t('texts.toDate')}
              value={endDate}
              sx={{ width: 1 }}
              maxDate={moment()}
              minDate={moment(startDate).add(1, 'day')}
              onChange={(value) =>
                isMonthSegmentation
                  ? setEndDate(moment(value).endOf('month'))
                  : setEndDate(value)
              }
              openTo={aggregation.toLocaleLowerCase()}
              views={['year', 'month', ...(isMonthSegmentation ? [] : ['day'])]}
              slotProps={
                isMonthSegmentation
                  ? {}
                  : {
                      textField: {
                        helperText: 'MM/DD/YYYY',
                      },
                    }
              }
            />
          </LocalizationProvider>
        </Stack>
      </Card>

      {shouldShowChart ? (
        chart
      ) : data !== null ? (
        <Typography sx={{ color: 'error.main', pl: 1 }}>
          {isMetricsInvalid
            ? t('phrases.statistics.chartAvailability')
            : t('texts.noDataAvailableForSelectedFilters')}
        </Typography>
      ) : null}

      {tableData?.length ? (
        <Card>
          <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
            <MuiTable
              headers={tableHeaders}
              rows={tableData}
              noDataMessage={t('texts.noDataAvailable')}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              scrollToBottomOnChange
            />
          </Box>
        </Card>
      ) : null}
    </Stack>
  );
};

export default Statistics;
