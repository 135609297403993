import React from 'react';
import RenderIf from '../../customComponents/RenderIf';
import Loader from '../loader/Loader';
import ReactCalendar from '../reactcalendar/ReactCalendar';
import './ClientSessionAssign.css';
import SelectExercisesModal from '../../components/SelectExercisesModal/SelectExercisesModal';
import SessionExercisesList from '../../components/SessionExercisesList/SessionExercisesList';
import { SessionOptionsSection } from './SessionOptionsSection';
import FormInput from '../../components/shared/FormInput';
import ImportSessionModal from '../../components/ImportSessionModal.js/ImportSessionModal';
import { Button, Stack } from '@mui/material';
import MuiModal from '../../components/shared/MuiModal';
import useClientSessionAssign from './useClientSessionAssign';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutline } from '@material-ui/icons';
import FinishedWorkoutsModal from '../../components/FinishedWorkoutsModal/FinishedWorkoutsModal';

export default function ClientSessionAssign() {
  const {
    teamId,
    clientId,
    sessions,
    weekDay,
    fullDate,
    currentExercises,
    expandExercise,
    loading,
    currentSessionDate,
    title,
    Instructions,
    deleteModalVisibility,
    importModalVisibility,
    exercisesModalVisibility,
    selectedSessionId,
    removedSessionsDates,
    workoutsData,
    viewWorkoutsDoneModal,
    newSessionsDates,
    handleWorkoutSimulation,
    setViewWorkoutsDoneModal,
    saveSessionToLibrary,
    setSelectedSessionId,
    setSelectedSession,
    preDate,
    nextDate,
    addSuperset,
    removeSuperset,
    setExpandExercise,
    onChangeDate,
    setCurrentExercises,
    sessionSubmit,
    sessionExists,
    setDeleteModalVisibility,
    setImportModalVisibility,
    setExercisesModalVisibility,
    handleAddExercisesToSession,
    handleImportSessionExercises,
    handleDeleteClientSession,
    setTitle,
    setInstructions,
    resetState,
  } = useClientSessionAssign();
  const { t } = useTranslation();

  const renderDateSelection = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        mb={2}
      >
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => preDate()}
          className="img-fluid"
          src="/assets/left-arrow-icon.png"
          alt=""
        />
        <span className="clr-green y-m-d-txt">{`${weekDay.slice(
          0,
          3,
        )}, ${fullDate}`}</span>{' '}
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => nextDate()}
          src="/assets/right-arrow-icon.png"
          alt="imagee"
        />
      </Stack>
    );
  };

  const renderSessionData = () => {
    return (
      <SessionExercisesList
        addSuperset={addSuperset}
        removeSuperset={removeSuperset}
        exercises={currentExercises}
        updateExercisesList={(e) => setCurrentExercises(e)}
        isExpanded={expandExercise}
      />
    );
  };

  return (
    <React.Fragment>
      <RenderIf isTrue={loading}>
        <Loader />
      </RenderIf>
      <SessionOptionsSection
        sessions={sessions}
        selectedSessionId={selectedSessionId}
        setSelectedSessionId={(id) => {
          setSelectedSessionId(id);
          setSelectedSession(sessions.find((session) => session._id === id));
        }}
        saveSessionToLibrary={saveSessionToLibrary}
        resetState={resetState}
        sessionDate={currentSessionDate}
        isExistentSession={sessionExists()}
        sessionSubmit={sessionSubmit}
        setExercisesModalVisibility={(e) => setExercisesModalVisibility(e)}
        setImportModalVisibility={(e) => setImportModalVisibility(e)}
        handleWorkoutSimulation={handleWorkoutSimulation}
        clientId={clientId}
        teamId={teamId}
      />
      <div className="cardio-body">
        <div className="cardio-content-body-info">
          <Stack
            alignItems="center"
            sx={{
              borderRight: 1.5,
              borderColor: 'rgba(255, 255, 255, 0.25)',
              paddingRight: 2,
              marginRight: 4,
            }}
          >
            <div className="cardio-calendar-content-info">
              {renderDateSelection()}

              <ReactCalendar
                selectedDate={currentSessionDate}
                setSession={true}
                clientId={clientId || ''}
                teamId={teamId || ''}
                setDate={onChangeDate}
                newSessionsDates={newSessionsDates}
                removedSessionsDates={removedSessionsDates}
              />
              {!!workoutsData?.workouts?.length && (
                <Button
                  variant="outlined"
                  sx={{ mt: 2, width: 1, gap: 1 }}
                  onClick={() => setViewWorkoutsDoneModal(true)}
                >
                  <CheckCircleOutline color="white" />
                  {t('buttons.finishedWorkouts')} (
                  {workoutsData.workouts.length})
                </Button>
              )}
            </div>
          </Stack>

          <Stack>
            <Stack direction="row" spacing={2}>
              <FormInput
                label={t('texts.clientSessionTitle')}
                onChange={(e) => setTitle(e.target.value)}
                name="title"
                placeholder={t('placeholders.enterSessionTitle')}
                value={title}
                sx={{ width: 250 }}
                inputSx={{ pr: 8, mr: 8 }}
              />
              <FormInput
                label={t('texts.clientSessionInstructions')}
                onChange={(e) => setInstructions(e.target.value)}
                name="Instructions"
                placeholder={t('placeholders.enterSessionInstructions')}
                value={Instructions}
                containerSx={{ width: '100%' }}
                inputSx={{ pr: 8, mr: 8 }}
              />

              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {currentExercises?.length ? (
                  <img
                    style={{ cursor: 'pointer', minHeight: 30, minWidth: 30 }}
                    onClick={() => setExpandExercise((prev) => !prev)}
                    expandExercise={expandExercise}
                    className="img-fluid"
                    src={
                      expandExercise
                        ? '/assets/lucide_shrink.png'
                        : '/assets/expand-icon.svg'
                    }
                    alt={
                      expandExercise
                        ? t('altTexts.compressedView')
                        : t('altTexts.expandedView')
                    }
                    title={
                      expandExercise
                        ? t('altTexts.compressedView')
                        : t('altTexts.expandedView')
                    }
                  />
                ) : null}
                {sessionExists() && (
                  <img
                    style={{ cursor: 'pointer', minHeight: 28, minWidth: 28 }}
                    onClick={() => setDeleteModalVisibility((prev) => !prev)}
                    src="/assets/bx_trash.svg"
                    className="img-fluid"
                    alt="Delete session"
                    title="Delete session"
                  />
                )}
              </Stack>
            </Stack>

            {renderSessionData()}
          </Stack>
        </div>
      </div>
      <SelectExercisesModal
        onClose={() => setExercisesModalVisibility(false)}
        isOpen={exercisesModalVisibility}
        addExercises={handleAddExercisesToSession}
      />
      <ImportSessionModal
        onClose={() => setImportModalVisibility(false)}
        isOpen={importModalVisibility}
        handleImport={handleImportSessionExercises}
      />
      <MuiModal
        open={deleteModalVisibility}
        onClose={() => setDeleteModalVisibility(false)}
        onSubmit={handleDeleteClientSession}
        title={t('modals.titles.deleteSession')}
        description={t('modals.phrases.areYouSureDeleteSession')}
        submitBtnText={t('buttons.confirm')}
        closeBtnText={t('buttons.cancel')}
      />
      {!!workoutsData?.workouts?.length && (
        <FinishedWorkoutsModal
          onClose={() => setViewWorkoutsDoneModal(false)}
          isOpen={viewWorkoutsDoneModal}
          workoutsData={workoutsData}
          handleSubmit={() => {}}
        />
      )}
    </React.Fragment>
  );
}
