import { Divider, Grid, Stack, Typography } from '@mui/material';
import MuiModal from '../../components/shared/MuiModal';
import { Print } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';

const SerieRow = ({ metric }) => (
  <Stack direction="row" gap={1} sx={{ opacity: 0.6 }}>
    <Typography sx={{ fontSize: 16, color: 'black' }}>{`${metric.value}${
      metric.unit || ''
    }`}</Typography>
    {metric.type && (
      <Typography sx={{ fontSize: 16, color: 'black' }}>
        ({metric.type})
      </Typography>
    )}
  </Stack>
);

const SerieColumnLabel = ({ text }) => (
  <Typography
    sx={{
      fontSize: 17,
      color: 'black',
      opacity: 1,
      textDecoration: 'underline',
    }}
  >
    {text}
  </Typography>
);

const ExerciseRow = ({
  exercise: {
    metadata,
    exerciseId: { ExerciseName },
  },
  letterText,
}) => (
  <Stack gap={1.5} mb={1}>
    <Typography sx={{ fontSize: 21, color: 'black' }}>
      <b>{letterText}</b> {ExerciseName}
    </Typography>

    <Grid container spacing={0.5}>
      <Grid item xs={3.5}>
        <SerieColumnLabel text="Metric 1" />
      </Grid>
      <Grid item xs={3.5}>
        <SerieColumnLabel text="Metric 2" />
      </Grid>
      <Grid item xs={2.5}>
        <SerieColumnLabel text="Tempo" />
      </Grid>
      <Grid item xs={2.5}>
        <SerieColumnLabel text="Rest" />
      </Grid>

      {metadata.map((serie) => (
        <>
          <Grid item xs={3.5}>
            <SerieRow metric={serie.firstMetric} />
          </Grid>
          <Grid item xs={3.5}>
            <SerieRow metric={serie.secondMetric} />
          </Grid>
          <Grid item xs={2.5}>
            <SerieRow metric={{ type: '', value: serie.tempo }} />
          </Grid>
          <Grid item xs={2.5}>
            <SerieRow metric={{ type: '', value: serie.rest }} />
          </Grid>
        </>
      ))}
    </Grid>
  </Stack>
);

const PrintSessionViewModal = ({ session, opened, onClose }) => {
  const { t } = useTranslation();
  const containerRef = useRef();

  const printSession = useReactToPrint({
    content: () => containerRef.current,
    bodyClass: 'session-to-print',
  });

  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

  console.log(session);

  return (
    <MuiModal
      open={opened}
      submitBtnIcon={<Print />}
      onClose={onClose}
      onSubmit={printSession}
      containerSx={{ maxHeight: '85vh' }}
      sx={{
        pb: 3,
        bgcolor: 'white',
        'scrollbar-width': 'none !important',
        '-ms-overflow-style': 'none !important',
      }}
      closeBtnText={t('buttons.cancel')}
      submitBtnText={t('buttons.printSession')}
      closeBtnOutlined={false}
      // isLoading={loading}
    >
      <Stack ref={containerRef} gap={2} mb={2} maxWidth={500}>
        <Stack gap={1} mb={1.5}>
          <Typography sx={{ fontSize: 30, color: 'black' }}>
            {session.title}
          </Typography>
          <Typography sx={{ fontSize: 16, opacity: 0.4, color: 'black' }}>
            {session.Instructions}
          </Typography>
        </Stack>
        <Stack gap={3} divider={<Divider sx={{ borderColor: 'black' }} />}>
          {session.assignedExercises?.map((exercises, indexI) => (
            <Stack
              gap={3}
              divider={<Divider sx={{ borderColor: 'black' }} />}
              sx={{
                border: 1.5,
                borderColor: 'rgba(0,0,0,0.1)',
                p: 2.5,
                py: 2,
                borderRadius: 2,
              }}
            >
              {exercises.map((exercise, indexJ) => (
                <ExerciseRow
                  exercise={exercise}
                  letterText={`${alphabet[indexI]}${
                    exercises.length > 1 ? `.${indexJ + 1}` : ''
                  })`}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </MuiModal>
  );
};

export default PrintSessionViewModal;
