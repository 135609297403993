import {
  Add,
  Close,
  EmailOutlined,
  PlusOneOutlined,
  Search,
} from '@mui/icons-material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import EmailInput from '../../components/shared/EmailInput';
import FormInput from '../../components/shared/FormInput';
import FormSelectInput from '../../components/shared/FormSelectInput';
import MuiButton from '../../components/shared/MuiButton';
import MuiModal from '../../components/shared/MuiModal';
import Chat from '../chat/Chat';
import useClientsList from './useClientsList';
import './ClientsList.css';
import ClientListItem from './ClientListItem';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ClientsList = () => {
  const {
    orderByOptions,
    clientStatusOptions,
    onAddNewClients,
    onChangeClientStatus,
    showAddClientModal,
    setShowAddClientModal,
    controlOptions,
    registerOptions,
    registerClientForm,
    errors,
    clientsData,
    setClientIdForChat,
    clientIdForChat,
    clientIdForStatus,
    setClientIdForStatus,
    setFocusChatMessage,
    focusChatMessage,
    handleCloseChat,
    onAddEmail,
    newClientsEmails,
    onRemoveEmail,
    onCloseTutorialModal,
    handleScroll,
    isTutorialModalOpened,
    selectedClient,
    loading,
  } = useClientsList();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const status = clientsData?.data?.[0]?.status;

  useEffect(() => {
    handleQueryParams();
  }, []);

  const handleQueryParams = () => {
    if (searchParams?.get('chat')) {
      setClientIdForChat(searchParams?.get('chat'));
      if (searchParams?.get('message')) {
        setFocusChatMessage(searchParams?.get('message'));
      }
    }
  };

  const getStatusChangeText = (status) => {
    switch (status) {
      case 'Active':
        return t('texts.deactivate');
      case 'Deactive':
        return t('texts.activate');
      default:
        return t('texts.cancelInvite');
    }
  };

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack direction="row" spacing={2} alignItems="flex-end">
          <FormInput
            name="filterByName"
            register={registerOptions}
            placeholder={t('placeholders.searchByClientName')}
            startAdornment={<Search sx={{ color: 'secondary.main', ml: 2 }} />}
            sx={{ width: 300, mt: 0.5, mb: -1 }}
          />
          <FormSelectInput
            name="filterByStatus"
            control={controlOptions}
            label={t('texts.filterByStatus')}
            selectOptions={clientStatusOptions}
            sx={{ width: 150 }}
            hideError={true}
          />
          <FormSelectInput
            name="orderBy"
            control={controlOptions}
            label={t('texts.orderBy')}
            selectOptions={orderByOptions}
            sx={{ width: 150 }}
          />
        </Stack>
        <Box>
          <MuiButton
            sx={{ mt: 1.7 }}
            onClick={() => setShowAddClientModal(true)}
            icon={<EmailOutlined />}
          >
            {t('buttons.inviteClient')}
          </MuiButton>
        </Box>
      </Stack>

      <Stack
        alignItems="center"
        sx={{
          height: 'calc(100vh - 150px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          pr: 1,
          pb: 2,
        }}
        onScroll={handleScroll}
      >
        {!clientsData?.data?.length && !loading ? (
          <Box sx={{ mt: 2, alignSelf: 'start !important' }}>
            <Typography>{t('texts.noClientsFound')}</Typography>
            <Typography>{t('texts.noClientsFoundTip')}</Typography>
          </Box>
        ) : (
          clientsData?.data?.map((user) => {
            return (
              <Box key={user._id} width="100%">
                <ClientListItem
                  hasNewChatMessages={user.newChatMessagesCount > 0}
                  user={user}
                  status={status}
                  setClientIdForChat={setClientIdForChat}
                  setClientIdForStatus={setClientIdForStatus}
                  getStatusChangeText={getStatusChangeText}
                />
              </Box>
            );
          })
        )}

        {!!clientsData?.data?.length && loading && <CircularProgress size={30} />}
        {clientsData.page === clientsData.lastPage && clientsData.data.length >= 10 && <Typography color="grey">End of client's list</Typography>}
      </Stack>

      <Chat
        open={!!clientIdForChat}
        closeTab={() => handleCloseChat()}
        clientId={clientIdForChat}
        focusMessage={focusChatMessage}
      />
      {selectedClient && (
        <MuiModal
          title={`${getStatusChangeText(selectedClient.status)}`}
          description={`${t('modals.phrases.areYouSure')} ${getStatusChangeText(
            selectedClient.status,
          ).toUpperCase()} ${t('phrases.thisClient')}`}
          open={!!clientIdForStatus}
          onClose={() => setClientIdForStatus('')}
          onSubmit={onChangeClientStatus}
          submitBtnText={t('buttons.confirm')}
        />
      )}

      <MuiModal
        title={t('texts.inviteNewClient')}
        open={showAddClientModal}
        onClose={() => setShowAddClientModal(false)}
        onSubmit={onAddNewClients}
        submitBtnText={t('buttons.sendInvite')}
        closeBtnText={t('buttons.cancel')}
        sx={{ maxWidth: 600, minWidth: 600 }}
      >
        <Box sx={{ my: 2 }}>
          <EmailInput
            label={t('texts.newEmail')}
            name="newClientEmail"
            placeholder={t('placeholders.enterClientEmail')}
            options={{}}
            register={registerClientForm}
            error={errors.newClientEmail}
            endAdornment={
              <Add onClick={onAddEmail} sx={{ cursor: 'pointer' }} />
            }
          />
          <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
            {t('texts.emails')}
          </Typography>

          {newClientsEmails?.length ? (
            <Box sx={{ display: 'inline-block', width: 1, mt: 0.5 }}>
              {newClientsEmails.map((email) => (
                <Box
                  sx={{
                    py: 1,
                    px: 2,
                    pr: 4.5,
                    mr: 1,
                    mt: 1,
                    display: 'inline-block',
                    borderRadius: 2.5,
                    bgcolor: 'primary.main',
                  }}
                >
                  <Typography>
                    {email}
                    <Close
                      sx={{ position: 'absolute', cursor: 'pointer', ml: 0.5 }}
                      onClick={() => onRemoveEmail(email)}
                      fontSize="medium"
                    />
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography sx={{ color: 'secondary.main', fontSize: 12, mt: 1 }}>
              {t('validations.addAtLeastOneEmail')}
            </Typography>
          )}
        </Box>
      </MuiModal>

      <MuiModal
        onClose={onCloseTutorialModal}
        open={isTutorialModalOpened}
        sx={{ width: '80%', maxWidth: 1000 }}
        sxButtons={{ mr: 1 }}
        onSubmit={onCloseTutorialModal}
        submitBtnText="Skip Tutorial"
        hideCloseBtn
        title="Beginner's Tutorial"
      >
        <Stack>
          <iframe
            title="tutorial-video"
            height="500"
            src="https://www.youtube.com/embed/DA-uKm7btgA"
            frameborder="0"
            allowfullscreen
          />
        </Stack>
      </MuiModal>
    </Stack>
  );
};

export default ClientsList;
