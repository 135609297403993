import './DashBoard.css';
import { Navigate, Routes, Route } from 'react-router-dom';
import NavBar from '../navbar/NavBar';
import SideBar, { SIDEBAR_WIDTH } from '../sidebar/Sidebar';
import ClientProfile from '../../pages/client-profile/ClientProfile';
import Workout from '../../pages/Workout/Workout';
import Program from '../../pages/Program/Program';
import TrainerProfile from '../../pages/trainer-profile/TrainerProfile';
import Notification from '../../pages/Notifications/Notification';
import PaymentInfo from '../../pages/payment/payment-info/PaymentInfo';
import UpdateExercise from '../../pages/updateExercise/UpdateExercise';
import LibraryPage from '../../pages/libraryPage/LibraryPage';
import Session from '../../pages/session/Session';
import ProgramSetting from '../../pages/programsetting/ProgramSetting';
import LibrarySession from '../../pages/LibrarySession/LibrarySession';
import SessionsCalendar from '../../pages/sessions-calendar/SessionsCalendar';
import ReactCalendar from '../../pages/reactcalendar/ReactCalendar';
import HasSubscription from '../../paymentAuth/HasSubscription';
import Subscription from '../../pages/payment/subscription/Subscription';
import PaymentSection from '../../stripe/payments/PaymentSection';
import PaymentForm from '../../stripe/PaymentForm';
import ClientsList from '../../pages/client/ClientsList';
import ClientSessionAssign from '../../pages/ClientSessionAssign/ClientSessionAssign';
import TeamList from '../../pages/team/TeamList';
import TeamMembers from '../../pages/teamMembers/TeamMembers';
import Statistics from '../../pages/statistics/Statistics';
import { Box, Stack } from '@mui/material';
import PaymentConfirmation from '../../pages/PaymentConfirmation/PaymentConfirmation';
import 'react-toastify/dist/ReactToastify.css';
import AllUsers from '../../pages/admin/all-users/AllUsers';
import VideoTutorial from '../../pages/video-tutorial/VideoTutorial';

const DashBoard = () => {
  return (
    <Stack className="Apps" sx={{ minHeight: '100vh' }} direction="row">
      <div
        className="sidebar"
        style={{ boxShadow: '4px 0px rgb(0 0 0 / 25%)' }}
      >
        <SideBar />
      </div>

      <Stack sx={{ width: '100%', height: '100%' }}>
        <NavBar />

        <Box sx={{ height: '100%', ml: SIDEBAR_WIDTH, px: 3 }}>
          <Routes>
            <Route element={<HasSubscription />}>
              <Route path="/client" element={<ClientsList />} />
              <Route path="/team" element={<TeamList />} />
              <Route path="/teams" element={<TeamList showAll />} />

              <Route
                path="/client/profile/:userId"
                element={<ClientProfile />}
              />
              <Route path="/program" element={<Program />} />
              <Route path="/workout/:id" element={<Workout />} />
              <Route path="/notification" element={<Notification />} />

              <Route path="/subscription" element={<Subscription />}></Route>
              <Route path="/paymentinfo" element={<PaymentInfo />} />
              <Route path="/videotutorial" element={<VideoTutorial />} />

              <Route path="/updateexercise" element={<UpdateExercise />} />
              <Route path="/librarypage" element={<LibraryPage />} />
              <Route path="/admin/users" element={<AllUsers />} />

              <Route path="/librarypage/sessions" element={<Session />} />
              <Route
                path="/librarypage/progset/:programId"
                element={<ProgramSetting />}
              />
              <Route path="/statistics" element={<Statistics />} />
              <Route
                path="/client/:clientId/session/:date"
                element={<ClientSessionAssign />}
              />
              <Route
                path="/client/:clientId/session/:date"
                element={<ClientSessionAssign />}
              />
              <Route
                path="/team/:teamId/session/:date"
                element={<ClientSessionAssign />}
              />
              <Route
                path="/librarypage/session/create"
                element={<LibrarySession />}
              />
              <Route
                path="/librarypage/session/:sessionId"
                element={<LibrarySession />}
              />
              <Route
                path="/client/calendars/:clientId"
                element={<SessionsCalendar />}
              />
              <Route
                path="/team/calendars/:teamId"
                element={<SessionsCalendar />}
              />
              <Route path="/reactcalendar" element={<ReactCalendar />} />
              <Route path="/team/:teamId/members" element={<TeamMembers />} />
              <Route path="*" element={<Navigate to="/client" replace />} />
              <Route
                element={<PaymentConfirmation />}
                path="/payment/confirmation"
              ></Route>
            </Route>

            <Route path="/settings" element={<TrainerProfile />} />
            <Route element={<PaymentSection />} path="/plans" />
            <Route element={<PaymentForm />} path="/payment/:price"></Route>
          </Routes>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DashBoard;
