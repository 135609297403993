import { Avatar, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import Tag from './Tag';

const Card = ({
  avatar,
  onClick,
  onAvatarClick,
  title,
  isCoach,
  hideTag,
  children,
}) => {
  return (
    <Stack
      className="boxes"
      sx={{ mx: 0, py: 3, pl: 4 }}
      direction="row"
      justifyContent="space-between"
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        <Avatar
          sx={{
            width: 80,
            height: 80,
            color: 'white',
            cursor: onAvatarClick ? 'pointer' : 'normal',
          }}
          src={avatar || '/broken-image.jpg'}
          onClick={onAvatarClick}
        />
        <Stack spacing={0.5}>
          {!hideTag && !isCoach && (
            <Tag
              sx={{ top: -25, left: -15, py: 0, width: 'min-content' }}
              text={t('texts.coach')}
            />
          )}
          <Typography sx={{ color: 'secondary.main', fontSize: 25 }}>
            {title}
          </Typography>
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default Card;
