import { Stack, Typography } from '@mui/material';

const Tag = ({ text, sx, thickBorder }) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    bgcolor="rgba(50, 180, 255, 0.75)"
    px={1}
    py={0.25}
    sx={{
      borderRadius: 500,
      border: `${thickBorder ? '2' : '1'}px solid white`,
      ...sx,
    }}
  >
    <Typography fontSize={12} color="white">
      {text}
    </Typography>
  </Stack>
);

export default Tag;
